// SPDX-License-Identifier: Apache-2.0

import PerunMessage from "./PerunMessage";
import { jsonMember, jsonObject } from "typedjson";
import ChannelID from "./types/channelID";
import { MessageType, MessageTypeName } from "./messageTypes";
import ProposalID from "./types/poposalID";

@jsonObject
export default class ChannelCreated extends PerunMessage {
  @jsonMember(ChannelID) id: ChannelID;
  @jsonMember(ProposalID) proposalID: ProposalID;
  @jsonMember(Number) idx: number;

  constructor(id: ChannelID, proposalID: ProposalID, idx: number) {
    super();
    this.id = id;
    this.proposalID = proposalID;
    this.idx = idx;
  }

  messageType(): MessageType {
    return ChannelCreated;
  }

  messageTypeName(): MessageTypeName {
    return "ChannelCreated";
  }
}
