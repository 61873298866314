// SPDX-License-Identifier: Apache-2.0

import { utils } from "ethers";
import { jsonObject } from "typedjson";
import CustomJSON from "../customjson";

@jsonObject
export default class ProposalID {
  value: Uint8Array;

  constructor(value: Uint8Array) {
    if (value.length !== 32) {
      throw new Error("invalid proposalID length");
    }
    this.value = value;
  }

  static fromJSON(val: any): ProposalID {
    return new ProposalID(utils.arrayify(val));
  }

  static toJSON(me: ProposalID) {
    return Array.from(me.value.values());
  }

  toString(): string {
    return utils.hexlify(this.value);
  }

  public equals(other: ProposalID): boolean {
    return this.value.toString() === other.value.toString();
  }
}

CustomJSON(ProposalID);
