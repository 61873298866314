// SPDX-License-Identifier: Apache-2.0

import Row from "react-bootstrap/Row";
import { Button, Spinner } from "react-bootstrap";
import React, { useContext, useState } from "react";
import { onboard } from "../web3/web3";
import { ethers } from "ethers";
import { AppContext } from "../AppContext";
import logo from "../images/perun_logo.png";
import { Client } from "../client/Client";
import { Connection } from "../wire/connection";
import { dispatchPerunError } from "../events/Error";
import { Web3Provider } from "../client/Provider";
import { useTranslation } from "react-i18next";
import * as config from "../config.json";
import ChainsList from "./ChainsList";

interface Props {
  toggleOnboarded: () => void;
}

export function Onboarding(props: Props) {
  const { t } = useTranslation();
  const ctx = useContext(AppContext);
  const [isConnecting, setIsConnecting] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [connection, setConnection] = useState(true);

  const handleProcessChange = (isProcessing: boolean) => {
    setIsProcessing(isProcessing);
  };

  const handleConnection = (connecting: boolean) => {
    setConnection(connecting);
  };

  const initClient = (
    provider: ethers.providers.Web3Provider,
    address: string
  ) => {
    const conn = new Connection(
      new URL(`${config.wsAddress}:${config.wsPort + config.wsPath}`)
    );
    const client = new Client(new Web3Provider(provider, address), conn);

    setIsConnecting(true);

    client
      .initializeConn()
      .then((success) => {
        if (success) {
          console.log("Client is initialized");
          ctx.setClient(client);
          props.toggleOnboarded();
          setIsConnecting(false);
        } else {
          dispatchPerunError(<p>{t("CouldNotInitialize")}</p>);
          setIsConnecting(false);
        }
      })
      .catch(() => {
        dispatchPerunError(<p>{t("WSNotConnected")}</p>);
        setIsConnecting(false);
      });
  };

  const getBody = (): JSX.Element => {
    if (isConnecting) {
      return <Spinner className={"loading-spinner"} animation={"border"} />;
    } else {
      return (
        <>
          <p>{t("supportedNetworks")}:</p>
          <Row className={"justify-content-center"}>
            <ChainsList
              onProcessChange={handleProcessChange}
              onConnected={handleConnection}
            />
          </Row>
        </>
      );
    }
  };

  return (
    <>
      <div className={"justify-content-center Onboarding"}>
        <Row className={"justify-content-center"}>
          <img id="perun-logo-large" height={"250"} src={logo} alt={""} />
        </Row>
        {getBody()}
        <Row className={"justify-content-center"}>
          <Button
            className={"my-auto"}
            disabled={isConnecting || isProcessing || !connection}
            variant="dark"
            onClick={async () => {
              let provider: ethers.providers.Web3Provider;
              let account: string;
              try {
                const networkIDs = config.networkIDs.map((id) => BigInt(id));
                const res = await onboard(networkIDs);
                provider = res.provider;
                account = res.account;
              } catch (err) {
                if (err instanceof Error) {
                  dispatchPerunError(<p>{err.message}</p>);
                } else {
                  dispatchPerunError(<p>{t("errorOnboarding")}</p>);
                }
                return;
              }
              initClient(provider, account);
            }}
          >
            {t("onboard")}
          </Button>
        </Row>
      </div>
    </>
  );
}
