// SPDX-License-Identifier: Apache-2.0

export interface Rejected {
  reason: string;
}

export type RejectItemType = "channel proposal" | "channel update";

/**
 * Returns the reject reason of an error received from a RejectItemType,
 * i.e., a channel proposal or a channel update proposal.
 *
 * @param err - The error received for sending a proposal.
 * @param actionType - The type of action for which the error was received for,
 * either a channel proposal or a channel update proposal.
 */
export function getRejectReason(
  err: any,
  actionType: RejectItemType
): Rejected | undefined {
  if (typeof err !== "string" || !err.includes("rejected")) {
    return;
  }
  let searchStr = "";
  if (actionType === "channel proposal") {
    searchStr = `${actionType.toString()}: `;
  }
  searchStr += `${actionType.toString()} rejected by peer: `;

  if (err.startsWith(searchStr)) {
    return { reason: err.substr(searchStr.length) };
  } else {
    return;
  }
}
