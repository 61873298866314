// SPDX-License-Identifier: Apache-2.0

import { jsonArrayMember, jsonMember, jsonObject } from "typedjson";
import { MessageType, MessageTypeName } from "./messageTypes";
import PerunMessage from "./PerunMessage";
import { Params, SState } from "./types/channelSignedState";
//import ChannelID from "./types/channelID";

@jsonObject()
export default class SignedState extends PerunMessage {
  @jsonMember(Params, {
    serializer: (value: Params) => {
      return {
        id: value.id,
        challengeDuration: value.challengeDuration,
        parts: value.parts,
        app: value.app,
        appType: value.appType,
        nonce: value.nonce,
        ledgerChannel: value.ledgerChannel,
        virtualChannel: value.virtualChannel,
      };
    },
  })
  params: Params;
  @jsonMember(SState, {
    serializer: (value: SState) => {
      return {
        id: value.id,
        version: value.version,
        app: value.app,
        appType: value.appType,
        allocation: value.allocation,
        data: value.data,
        isFinal: value.isFinal,
      };
    },
  })
  state: SState;
  @jsonArrayMember(String) sigs: Array<String>;

  constructor(params: Params, state: SState, sigs: Array<String>) {
    super();
    this.params = params;
    this.state = state;
    this.sigs = sigs;
  }
  messageType(): MessageType {
    return SignedState;
  }
  messageTypeName(): MessageTypeName {
    return "SignedState";
  }
}
