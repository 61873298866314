import { Form, Row } from "react-bootstrap";
import Address from "../../api/types/address";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { utils } from "ethers";

interface Props {
  ownAddress: Address;
  submit: (receiverAddress: String, oC: Boolean) => void;
}

function ReceiverSelection(props: Props) {
  const { t } = useTranslation();
  const [ownChecked, setOwnChecked] = useState(true);
  const [otherReceiverAddress, setOtherReceiverAddress] = useState<String>();

  useEffect(() => {
    props.submit(otherReceiverAddress!, ownChecked);
  }, [otherReceiverAddress]);
  const getOwnAddressLabel = (): JSX.Element => {
    const strAddr = props.ownAddress.toString();
    return (
      <>
        {t("atMyOwnAddress")} (
        <div className={"address"} style={{ display: "inline-block" }}>
          {`${strAddr.substring(0, 5)}...${strAddr.substring(38, 42)}`}
        </div>
        )
      </>
    );
  };

  const checkAddress = (
    ev: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >
  ) => {
    if (utils.isAddress(ev.target.value)) {
      ev.target.setCustomValidity("");
    } else {
      ev.target.setCustomValidity("invalid");
    }
  };

  return (
    <>
      <Form.Label className={"padding"} style={{ fontSize: "110%" }}>
        {" "}
        {t("whereReceiveSwap")}{" "}
      </Form.Label>
      <Row className={"justify-content-center"}>
        <Form.Group className="mb-3 receiver-address-form">
          <Form.Check
            className="form-check-input"
            custom
            id={"own-recv-address-check"}
            checked={ownChecked}
            onChange={() => setOwnChecked(true)}
            label={getOwnAddressLabel()}
          />
          <Form.Check
            className="form-check-input"
            custom
            checked={!ownChecked}
            onChange={() => setOwnChecked(false)}
            id={"other-recv-address-check"}
            label={t("atADifferentAddress")}
          />
          <Form.Group className={"address-input"}>
            <Form.Control
              id={"receiver-address-input"}
              type={"text"}
              disabled={ownChecked}
              required
              placeholder={"Receiver Address"}
              onChange={(event) => {
                checkAddress(event);
                setOtherReceiverAddress(event.target.value);
              }}
            />
            <Form.Control.Feedback type={"invalid"}>
              {t("enterValidEthAddress")}
            </Form.Control.Feedback>
          </Form.Group>
        </Form.Group>
      </Row>
    </>
  );
}

export default ReceiverSelection;
