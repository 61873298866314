// SPDX-License-Identifier: Apache-2.0

import React, { useContext, useEffect, useState } from "react";
import { SwapJSON } from "../channel/Swap";
import { TypedJSON } from "typedjson";
import { Container, Table } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Hubs, getExplorerURL } from "../constants/Constants";
import { AppContext } from "../AppContext";
import Asset from "../api/types/asset";
import { getTokenCode } from "./forms/AmountAndAssetForm";

function History() {
  const { t } = useTranslation();
  const ctx = useContext(AppContext);
  const [swaps, setSwaps] = useState<SwapJSON[]>([]);
  const [assetDecimals, setAssetDecimals] = useState<Map<string, number>>(
    new Map<string, number>()
  );

  useEffect(() => {
    let swapsJSON: Array<SwapJSON>;
    try {
      swapsJSON =
        TypedJSON.parseAsArray(localStorage.getItem("swaps"), SwapJSON) ||
        new Array<SwapJSON>();
    } catch (e) {
      console.error("swap history in localStorage is malformed, resetting...");
      swapsJSON = new Array<SwapJSON>();
    }
    setSwaps(swapsJSON);
    setDecimals(swapsJSON);
  }, []);

  const setDecimals = async (swaps: SwapJSON[]) => {
    const decMap = new Map<string, number>();
    const setAssetDec = async (asset: Asset) => {
      if (!decMap.has(asset.code)) {
        try {
          const dec = await ctx.client.getDecimals(asset.toChannelAsset());
          if (dec) {
            decMap.set(asset.code, dec);
          }
        } catch (e) {
          console.log(e);
        }
      }
    };
    for (const swap of swaps) {
      await Promise.all([
        setAssetDec(swap.assets[0]),
        setAssetDec(swap.assets[1]),
      ]);
    }
    setAssetDecimals(decMap);
  };

  const getBalText = (swap: SwapJSON, index: 0 | 1): string => {
    try {
      const bal =
        Number(swap.balances[index].valueOf()) /
        10 ** (assetDecimals.get(swap.assets[index].code) || 0);
      return bal.toString() + " " + getTokenCode(swap.assets[index]);
    } catch (e) {
      console.log(e);
      return "0 " + getTokenCode(swap.assets[index]);
    }
  };

  return (
    <Container className={"HistoryContainer mt-2 w-75"} fluid>
      <div className={"ListViewWrapper"}>
        <Table>
          <thead>
            <tr>
              <th>{t("from")}</th>
              <th>{t("to")}</th>
              <th>{t("hub")}</th>
              <th>{t("receiver")}</th>
              <th>{t("date")}</th>
            </tr>
          </thead>
          <tbody>
            {swaps
              .slice()
              .reverse()
              .map((swap) => (
                <tr key={swap.swapID}>
                  <td>{getBalText(swap, 0)}</td>
                  <td>{getBalText(swap, 1)}</td>
                  <td>
                    <a
                      href={getExplorerURL(
                        swap.assets[1].chainID,
                        swap.peerAddress,
                        "address"
                      )}
                      style={{ color: "#00C9B8" }}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {Hubs.get(swap.assets[1].chainID)?.name || "Hub"}
                    </a>
                  </td>
                  {swap.receiverAddress ? (
                    <td className={"address"}>
                      {swap.receiverAddress.toString()}
                    </td>
                  ) : (
                    <td>{t("you")}</td>
                  )}
                  {new Date(swap.date).toLocaleString() === "Invalid Date" ? (
                    <td>{t("unknown")}</td>
                  ) : (
                    <td>{new Date(swap.date).toLocaleString()}</td>
                  )}
                </tr>
              ))}
          </tbody>
        </Table>
      </div>
    </Container>
  );
}

export default History;
