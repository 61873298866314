// SPDX-License-Identifier: Apache-2.0

import Address from "../api/types/address";
import ChannelState from "../api/types/channelState";
import ProposalID from "../api/types/poposalID";

/**
 * The representation of a proposal for the client.
 */
export default class Proposal {
  public readonly id: ProposalID;
  public readonly peerAddress: Address;
  public readonly state: ChannelState;

  constructor(id: ProposalID, peerAddress: Address, state: ChannelState) {
    this.id = id;
    this.peerAddress = peerAddress;
    this.state = state;
  }
}
