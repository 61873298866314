import { BigNumber as bn } from "bignumber.js";
import { truncateDecimal } from "../web3/balanceUtils";
import {
  approval,
  concludeCrossChain,
  concludeSameChain,
  erc20Deposit,
  erc20Withdrawal,
  ethDeposit,
  ethWithdrawal,
} from "../constants/Constants";
import Asset from "../api/types/asset";

// calculateTransactionFee adds all transaction fees up for each chain and returns them in the correct currency using the quotes.
export function calculateTransactionFee(
  assets: Map<String, Asset>,
  fromAsset: String,
  toAsset: String,
  crossQuote: number,
  fromQuote: number,
  toQuote: number,
  fromGas: String,
  toGas: String
): {
  fromTxFee: String;
  toTxFee: String;
  exchangeRate: number;
} {
  let fromTxFee = "";
  let toTxFee = "";
  // For Cross-Chain swaps we conclude on both chains, so concludeFinal is set to concludeCrossChain.
  // For Same-Chain swaps we conclude only once, so we set concludeFinal for one asset to 0 and one asset to concludeSameChain.
  // isFromAssetERC20, isToAssetERC20, isFromAssetETH, isToAssetETH are used to check the asset types.
  const isSameChain =
      assets.get(fromAsset!)!.chainID === assets.get(toAsset!)!.chainID,
    isFromAssetERC20 = assets.get(fromAsset!)!.type === "ERC20",
    isToAssetERC20 = assets.get(toAsset!)!.type === "ERC20",
    isFromAssetETH = assets.get(fromAsset!)!.type === "ETH",
    isToAssetETH = assets.get(toAsset!)!.type === "ETH",
    concludeGasUsedETHFrom = isSameChain
      ? isFromAssetETH
        ? concludeSameChain
        : 0
      : concludeCrossChain,
    concludeGasUsedETHTo = isSameChain
      ? isFromAssetETH
        ? 0
        : concludeSameChain
      : concludeCrossChain,
    concludeGasUsedERC20From = isSameChain
      ? isFromAssetETH
        ? concludeSameChain
        : 0
      : concludeCrossChain,
    concludeGasUsedERC20To = isSameChain
      ? isFromAssetETH
        ? 0
        : concludeSameChain
      : concludeCrossChain,
    ethFromGas = new bn(fromGas as string).div(new bn(10).pow(18)),
    ethToGas = new bn(toGas as string).div(new bn(10).pow(18));

  if (isFromAssetERC20) {
    fromTxFee = ethFromGas
      .times(new bn(erc20Withdrawal).plus(new bn(concludeGasUsedERC20From)))
      .times(fromQuote)
      .toString();
  } else if (isFromAssetETH) {
    fromTxFee = ethFromGas
      .times(new bn(ethWithdrawal).plus(new bn(concludeGasUsedETHFrom)))
      .toString();
  }

  if (isToAssetERC20) {
    const gas = ethToGas
      .times(
        new bn(erc20Withdrawal)
          .plus(new bn(concludeGasUsedERC20To))
          .plus(new bn(erc20Deposit))
          .plus(new bn(approval))
      )
      .times(toQuote);
    toTxFee = gas.toString();
  } else if (isToAssetETH) {
    const gas = ethToGas.times(
      new bn(ethWithdrawal)
        .plus(new bn(ethDeposit))
        .plus(new bn(concludeGasUsedETHTo))
    );
    toTxFee = gas.toString();
  }

  return { fromTxFee: fromTxFee, toTxFee: toTxFee, exchangeRate: crossQuote };
}

// updateFromAmount gets the input on the user's chain and returns the input and swapAmounts.
export function updateFromAmount(
  amount: string,
  exchangeRate: number,
  fromGas: String,
  toGas: String,
  fromAssetPrecision: number,
  toAssetPrecision: number
): { fromAmount: string; toAmount: string; toInput: string } {
  const amountNum = parseFloat(amount);
  if (isNaN(amountNum)) {
    return { fromAmount: "", toAmount: "", toInput: "" };
  }

  // fromAmount = input + transaction fee on user's chain.
  const fromAmount = truncateDecimal(
    new bn(amountNum).plus(new bn(fromGas as string)).toString(),
    fromAssetPrecision,
    "UP"
  );

  // balInput = input * rate
  const balInput = Number(bn(amountNum).times(exchangeRate)).toString();

  // truncBalAmount = input * rate - transaction fees on hub's chain.
  const truncBalAmount = truncateDecimal(
    Number(
      new bn(amountNum).times(exchangeRate).minus(new bn(toGas as string))
    ).toString(),
    toAssetPrecision,
    "DOWN"
  );
  return {
    fromAmount: fromAmount.toString(),
    toAmount: truncBalAmount,
    toInput: balInput,
  };
}

// updateToAmount gets the input on the hub's chain and returns the input and swapAmounts.
export function updateToAmount(
  amount: string,
  exchangeRate: number,
  fromGas: String,
  toGas: String,
  fromAssetPrecision: number,
  toAssetPrecision: number
): { fromAmount: string; toAmount: string; fromInput: string } {
  const amountNum = parseFloat(amount);
  if (isNaN(amountNum)) {
    return { fromAmount: "", toAmount: "", fromInput: "" };
  }

  // toAmount = input - transaction fees on hub's chain.
  const toAmount = truncateDecimal(
    new bn(amountNum).minus(new bn(toGas as string)).toString(),
    toAssetPrecision,
    "DOWN"
  );

  // truncBalAmount = toAmount / rate + transaction fees on user's chain.
  const truncBalAmount = truncateDecimal(
    Number(
      new bn(toAmount).div(exchangeRate).plus(new bn(fromGas as string))
    ).toString(),
    fromAssetPrecision,
    "UP"
  );

  // balInput = input / rate.
  const balInput = Number(new bn(amountNum).div(exchangeRate)).toString();
  return {
    fromAmount: truncBalAmount,
    toAmount: toAmount.toString(),
    fromInput: balInput,
  };
}

// calculateMinAmount calculates the minimum swap amount: input = transaction fees on hub's chain / rate.
export function calculateMinAmount(
  exchangeRate: number,
  toTXFee: String,
  precision: number
): String {
  const min = truncateDecimal(
    new bn(toTXFee as string).div(exchangeRate).toString(),
    precision,
    "UP"
  );
  if (parseFloat(min) < 0) {
    return "0.0";
  }
  return min;
}
