// SPDX-License-Identifier: Apache-2.0

import { ethers } from "ethers";
import i18n from "i18next";
import Decimal from "decimal.js";

export async function getBalance(
  provider: ethers.providers.Web3Provider,
  address: string
): Promise<bigint> {
  let balance: ethers.BigNumber;
  try {
    balance = await provider.getBalance(address);
  } catch {
    throw Error(i18n.t("errorNetwork"));
  }
  return balance.toBigInt();
}

/**
 * Truncates the decimal amount by rounding to the given precision.
 *
 * @param amount -
 * @param precision -
 * @param roundMode - Whether to round up or down.
 * @returns - The truncated amount.
 */
export function truncateDecimal(
  amount: string,
  precision: number,
  roundMode: "UP" | "DOWN"
): string {
  const d = new Decimal(amount);
  let round = roundMode;
  if (d.cmp(0.0) < 0) {
    round = roundMode === "UP" ? "DOWN" : "UP";
  }
  if (precision) {
    if (round === "UP") {
      return d.toDecimalPlaces(precision, Decimal.ROUND_UP).toFixed(precision);
    } else {
      return d
        .toDecimalPlaces(precision, Decimal.ROUND_DOWN)
        .toFixed(precision);
    }
  } else {
    if (round === "UP") {
      return d.ceil().toString();
    } else {
      return d.floor().toString();
    }
  }
}
