// SPDX-License-Identifier: Apache-2.0

import Modal from "react-bootstrap/Modal";
import { useTranslation } from "react-i18next";

export interface ErrorProps {
  show: boolean;
  title?: string;
  content?: JSX.Element;
}

/**
 * Returns a Modal with the given title and content.
 * @param props -
 * @constructor
 */
export default function ErrorModal(props: ErrorProps) {
  const { t } = useTranslation();

  return (
    <Modal
      className="errormodal"
      aria-labelledby="contained-modal-title-vcenter"
      show={props.show}
      centered
      backdrop={"static"}
      keyboard={false}
    >
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">
          {props.title ? props.title : t("information")}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>{props.content || <></>}</Modal.Body>
    </Modal>
  );
}
