// SPDX-License-Identifier: Apache-2.0

import { FormEvent } from "react";
import { Button, Form, Row } from "react-bootstrap";
import { BsArrowDown } from "react-icons/bs";
import { truncateDecimal } from "../../web3/balanceUtils";
import {
  assetPrecisions,
  ChainNames,
  getExplorerURL,
} from "../../constants/Constants";
import { useTranslation } from "react-i18next";
import { SwapInfo } from "../../channel/Swap";
import { getTokenCode } from "./AmountAndAssetForm";

interface Props {
  swapInfo: SwapInfo;
  exchangeRate: number;
  receiverAddress: string;
  hubName: string;
  hubURL: string;
  submit: () => void;
  cancel: () => void;
}

function SwapSummary(props: Props) {
  const { t } = useTranslation();
  const fromAsset = props.swapInfo.fromAsset;
  const toAsset = props.swapInfo.toAsset;

  const onSubmitHandler = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    props.submit();
  };

  const getChainName = (chainID: bigint): string => {
    return ChainNames.has(chainID)
      ? ChainNames.get(chainID)!
      : t("unknownNetwork");
  };

  return (
    <Row className={"flex-fill d-flex justify-content-start"}>
      <Form className={"swap-form s-mt-0"} onSubmit={onSubmitHandler}>
        <Form.Label>Swap Summary</Form.Label>
        <div className={"swap-summary"}>
          <p style={{ fontWeight: "bold", marginBottom: "0" }}>
            {t("youSend")} {props.swapInfo.fromAmount}{" "}
            {fromAsset.isERC20Token ? (
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={getExplorerURL(
                  fromAsset.chainID,
                  fromAsset.address,
                  "token"
                )}
                style={{ color: "#00C9B8" }}
              >
                {getTokenCode(fromAsset)}
              </a>
            ) : (
              getTokenCode(fromAsset)
            )}
          </p>
          <p>
            {t("on").toLowerCase()} {getChainName(fromAsset.chainID)}
          </p>

          <BsArrowDown id={"swap-arrow-down"} />
          <p>
            {t("rate")}: 1 {getTokenCode(toAsset)} ≈{" "}
            {truncateDecimal(
              Number(1 / props.exchangeRate).toString(),
              assetPrecisions.get(fromAsset.code) || 0,
              "UP"
            )}{" "}
            {getTokenCode(fromAsset)}
          </p>

          <p style={{ fontWeight: "bold", marginBottom: "0" }}>
            {t("andReceive")} {props.swapInfo.toAmount}{" "}
            {toAsset.isERC20Token ? (
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={getExplorerURL(toAsset.chainID, toAsset.address, "token")}
                style={{ color: "#00C9B8" }}
              >
                {getTokenCode(toAsset)}
              </a>
            ) : (
              getTokenCode(toAsset)
            )}
          </p>
          <p>
            {t("on").toLowerCase()} {getChainName(toAsset.chainID)}
          </p>

          <p>
            {t("at").toLowerCase()}{" "}
            <span className={"address"}>{props.receiverAddress}</span>
          </p>

          <p>
            {t("swapPerformedWith")}:{" "}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={props.hubURL}
              style={{ color: "#00C9B8" }}
            >
              {props.hubName}
            </a>
          </p>
        </div>

        <div className={"asset-form-submit"}>
          <Button variant={"secondary"} onClick={props.cancel}>
            {t("cancel")}
          </Button>
          <Button
            className={"custom-button"}
            variant={"primary"}
            type={"submit"}
          >
            {t("confirmSwap")}
          </Button>
        </div>
      </Form>
    </Row>
  );
}

export default SwapSummary;
