// SPDX-License-Identifier: Apache-2.0

import { ethers } from "ethers";
import { Network } from "@ethersproject/networks/src.ts/types";

export const startNetworkChangeDetection = (
  provider: ethers.providers.Web3Provider,
  onNetworkChange: (newNetwork: Network) => void
) => {
  provider.on("network", onNetworkChange);
  return () => {
    provider.off("network", onNetworkChange);
  };
};
