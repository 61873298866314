// SPDX-License-Identifier: Apache-2.0

import { utils } from "ethers";
import { jsonObject } from "typedjson";
import CustomJSON from "../customjson";

@jsonObject
export default class ChannelID {
  value: Uint8Array;

  constructor(value: Uint8Array) {
    if (value.length !== 32) {
      throw new Error("invalid channelId length");
    }
    this.value = value;
  }

  static fromJSON(val: any): ChannelID {
    return new ChannelID(utils.arrayify(val));
  }

  static toJSON(me: ChannelID) {
    return Array.from(me.value.values());
  }

  toString(): string {
    return utils.hexlify(this.value);
  }

  public equals(other: ChannelID): boolean {
    return this.value.toString() === other.value.toString();
  }
}

CustomJSON(ChannelID);
