// SPDX-License-Identifier: Apache-2.0

import PerunMessage from "./PerunMessage";
import { jsonMember, jsonObject } from "typedjson";
import { MessageType, MessageTypeName } from "./messageTypes";

@jsonObject
export default class Error extends PerunMessage {
  @jsonMember(String) error: string;

  constructor(error: string) {
    super();
    this.error = error;
  }

  messageType(): MessageType {
    return Error;
  }

  messageTypeName(): MessageTypeName {
    return "Error";
  }
}
