// SPDX-License-Identifier: Apache-2.0

import i18n from "i18next";
import { AssetType } from "../api/types/asset";
import SignedState from "../api/SignedState";
import { BiCopy } from "react-icons/bi";
import { ChainNames } from "../constants/Constants";

// If set to true, no notices are shown and the onSubmit function will be
// directly called.
let noticesDeactivated = false;
export const deactivateNotices = () => {
  noticesDeactivated = true;
};

export interface PerunNotice {
  title: string;
  content: JSX.Element;
  submitText?: string;
  onSubmit?: (msg?: SignedState) => void;
  cancelText?: string;
  onCancel?: () => void;
  autoClose?: Boolean;
}

export const dispatchPerunNotice = (notice: PerunNotice) => {
  if (noticesDeactivated) {
    notice.onSubmit?.();
    return;
  }
  document.dispatchEvent(
    new CustomEvent<PerunNotice>("PerunNotice", {
      bubbles: true,
      detail: notice,
      cancelable: false,
    })
  );
};

export const revertPerunNotice = () => {
  document.dispatchEvent(new CustomEvent<JSX.Element>("ClosePerunNotice"));
};

export const swapRateInfoNotice = (
  fromTxFee: String,
  toTxFee: String,
  exchangeRate: number,
  fromChainID: bigint,
  toChainID: bigint,
  fromAssetCode: string,
  toAssetCode: string
): PerunNotice => {
  const content = (
    <>
      <b>{i18n.t("noticeExchangeRate.intro")}</b>
      <b>
        {i18n.t("noticeExchangeRate.rateCalculation", {
          fromAssetCode: fromAssetCode,
          toAssetCode: toAssetCode,
        })}
      </b>
      <br />
      <p>
        {i18n.t("noticeExchangeRate.rate")} = {exchangeRate}
      </p>
      <p>
        {i18n.t("noticeExchangeRate.fromTxFee", {
          fromChainName: ChainNames.get(fromChainID),
          assetName: fromAssetCode,
        })}
        {"\n = "}
        {fromTxFee} {fromAssetCode}
      </p>
      <p>
        {i18n.t("noticeExchangeRate.toTxFee", {
          toChainName: ChainNames.get(toChainID),
          assetName: toAssetCode,
        })}
        {"\n = "}
        {toTxFee} {toAssetCode}
      </p>
    </>
  );
  return {
    title: i18n.t("noticeExchangeRate.title"),
    content: content,
    submitText: `${i18n.t("okay")}!`,
    onSubmit: () => {},
  };
};

export const initiateSwapNotice = (
  fromAssetName: string,
  assetType: AssetType,
  onSubmit: () => void,
  onCancel: () => void
): PerunNotice => {
  const content = (
    <>
      <b>{i18n.t("noticeInitiateSwap.part1a")}</b>
      <p>{i18n.t("noticeInitiateSwap.part1")}:</p>
      <ol>
        <li>{i18n.t("noticeInitiateSwap.part2")}</li>
        {assetType === "ERC20" && (
          <li>
            {i18n.t("noticeInitiateSwap.part3ERC20", {
              assetName: fromAssetName,
            })}
          </li>
        )}
        <li>
          {i18n.t("noticeInitiateSwap.part4", {
            assetName: fromAssetName,
          })}
        </li>
      </ol>
      <b>{i18n.t("noticeInitiateSwap.part5")}.</b>
    </>
  );

  return {
    title: i18n.t("metaMaskNotice"),
    content: content,
    submitText: `${i18n.t("okay")}!`,
    onSubmit: onSubmit,
    cancelText: `${i18n.t("cancel")}`,
    onCancel: onCancel,
  };
};

export const performingSwapNotice = (signedState?: string): PerunNotice => {
  return {
    title: i18n.t("SwapUpdate"),
    content: (
      <>
        <p>{i18n.t("noticeSwapUpdate")}.</p>
        {signedState && (
          <>
            <p>{i18n.t("saveState_funded")}</p>
            <textarea
              readOnly
              className="scrollabletextbox"
              defaultValue={signedState}
              id="note"
            ></textarea>
            <div
              className="copy-button"
              id="copyButton"
              onClick={() => copyToClipboard(signedState)}
            >
              <BiCopy size={20} />
            </div>
          </>
        )}
      </>
    ),
    autoClose: true,
  };
};

export const finalizingSwapNotice = (signedState?: string): PerunNotice => {
  const content = (
    <>
      <b>{i18n.t("noticeSwapClosing.part1")}.</b>
      <p> {i18n.t("noticeSwapClosing.part2")}</p>
      {signedState && (
        <>
          <p>{i18n.t("saveState_concluded")}</p>
          <textarea
            readOnly
            className="scrollabletextbox"
            defaultValue={signedState}
            id="note"
          ></textarea>
          <div
            className="copy-button"
            id="copyButton"
            onClick={() => copyToClipboard(signedState)}
          >
            <BiCopy size={20} />
          </div>
        </>
      )}
    </>
  );
  return {
    title: i18n.t("SwapFinalize"),
    content: content,
    autoClose: true,
  };
};

function copyToClipboard(signedState: string): void {
  navigator.clipboard.writeText(signedState).then(
    () => {
      console.log("Content copied to clipboard");

      // Resolved - text copied to clipboard successfully
    },
    () => {
      console.log("Failed to copy");
      // Rejected - text failed to copy to the clipboard
    }
  );
}
