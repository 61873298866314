// SPDX-License-Identifier: Apache-2.0

import PerunMessage from "../api/PerunMessage";
import { TypedJSON } from "typedjson";
import PerunObject from "../api/PerunObject";
import Request from "../api/Request";
import Response from "../api/Response";
import ErrorMsg from "../api/Error";
import Address from "../api/types/address";
import Initialize from "../api/Initialize";
import {
  RequestHandler,
  RequestTypeMessage,
  RequestType,
  ChannelEvent,
  ChannelEventHandler,
  ChannelEventMessage,
} from "./connEvents";
import SendTx from "../api/SendTx";
import ChannelProposal from "../api/ChannelProposal";
import UpdateChannel from "../api/UpdateChannel";
import SignData from "../api/SignData";
import ChannelCreated from "../api/ChannelCreated";
import ChannelClosed from "../api/ChannelClosed";
import { connectionTimeoutMS } from "../constants/Constants";
import FundingError from "../api/FundingError";

/**
 * Is responsible for the communication between the websocket and the client.
 */
export class Connection {
  public ws?: WebSocket;
  private readonly url: string;
  // The mapping of request types to its set handlers, which return a promise
  // to the response message.
  private requestHandlers: Map<RequestType, Function>;
  // The handler for channel events.
  private channelEventHandler: Map<ChannelEvent, Function>;
  // Contains the resolve and reject methods used to signal success of error of
  // the initialization.
  private initPromise: [(val: any) => void, (val: string) => void] | undefined;
  // The mapping of request IDs to the Promise's resolve and reject methods.
  private requestPromises: Map<
    number,
    [(val: PerunMessage) => void, (val: string) => void]
  >;
  private requestCounter: number;

  /**
   * Initializes the connection.
   *
   * @param url The URL of the websocket.
   */
  constructor(url: URL) {
    this.url = url.toString();
    this.requestHandlers = new Map();
    this.channelEventHandler = new Map();

    this.requestPromises = new Map<
      number,
      [(val: PerunMessage) => void, (val: string) => void]
    >();
    this.requestCounter = 0;
  }

  /**
   * Connects to the websocket.
   *
   * @returns A promise which resolves after the connection has been established.
   * @throws - An error if the timeout has been exceeded.
   */
  public connect(): Promise<void> {
    const ws = new WebSocket(this.url);

    const promise = new Promise<void>((resolve, reject) => {
      ws.onopen = () => {
        this.ws = ws;
        this.ws.onmessage = (ev) => this.onMessage(ev);
        resolve();
      };
      ws.onerror = () => reject();
    });

    return promiseWithTimeout(connectionTimeoutMS, promise);
  }

  /**
   * @returns True if the connection is ready for communication.
   */
  public isConnected(): boolean {
    return this.ws !== undefined && this.ws.readyState === WebSocket.OPEN;
  }

  /**
   * Calls Initialize on the websocket.
   *
   * @param address - The address of the websocket client.
   * @returns A promise that is fulfilled once the Perun client has
   * been initialized.
   */
  public initialize(address: Address): Promise<any> {
    const promise = new Promise<any>(
      (resolve, reject) => (this.initPromise = [resolve, reject])
    );
    this.sendMessage(new Initialize(address));
    return promise;
  }

  /**
   * Sends a request which contains the given message to the websocket.
   *
   * @param message - The message which is wrapped in a Request.
   * @returns A Promise to the Response of the Request.
   *
   */
  public request(message: PerunMessage): Promise<PerunMessage> {
    const reqID = this.requestCounter++;

    const promise = new Promise<PerunMessage>((resolve, reject) =>
      this.requestPromises.set(reqID, [resolve, reject])
    );

    const req = new Request(reqID, new PerunObject(message));
    this.sendMessage(req);
    return promise;
  }

  /**
   * Sets a request handler for a specific request type. This handler is
   * executed everytime a message of this type is received.
   *
   * @param requestType -
   * @param handler -
   * @public
   */
  public onRequest<T extends RequestType>(
    requestType: T,
    handler: RequestHandler<T>
  ) {
    this.requestHandlers.set(requestType, handler);
  }

  /**
   * Sets the handler for a specific channel event.
   *
   * @param event -
   * @param handler -
   */
  public onChannelEvent<T extends ChannelEvent>(
    event: T,
    handler: ChannelEventHandler<T>
  ) {
    this.channelEventHandler.set(event, handler);
  }

  /**
   * Sends a message to the websocket.
   *
   * @param message - The PerunMessage that is wrapped in a JSONObject and sent
   * to the websocket.
   * @private
   */
  private sendMessage(message: PerunMessage) {
    if (!this.ws) {
      throw new Error("uninitialised connection");
    } else if (this.ws.readyState !== WebSocket.OPEN) {
      throw new Error("websocket connection is not open");
    }
    const obj = new PerunObject(message);
    const b = TypedJSON.stringify(obj, PerunObject);
    this.ws.send(b);
  }

  /**
   * Is called everytime the a MessageEvent is received.
   *
   * @param ev -
   * @private
   */
  private onMessage(ev: MessageEvent) {
    let obj: PerunObject | undefined;
    try {
      obj = TypedJSON.parse(ev.data, PerunObject);
    } catch (e) {
      console.error("Received unsupported message");
      return;
    }

    if (!obj || !obj.message) {
      return Connection.handleUnknownMessage(ev);
    }

    const msg = obj.message;
    switch (msg.messageTypeName()) {
      case "Request":
        this.handleRequest(msg as Request);
        return;
      case "Response":
        this.handleResponse(msg as Response);
        return;
      case "Initialized":
        this.handleInitialized(null);
        return;
      case "ChannelCreated":
        this.handleChannelEvent("channelCreated", msg as ChannelCreated);
        return;
      case "FundingError":
        this.handleChannelEvent("fundingError", msg as FundingError);
        return;
      case "ChannelClosed":
        this.handleChannelEvent("channelClosed", msg as ChannelClosed);
        return;
      case "Error":
        // If an error is received that is not directed to a request, the initialization failed.
        this.handleInitialized(msg as ErrorMsg);
        return;
      default:
        console.error(`Received unknown message type ${msg.messageTypeName()}`);
        return;
    }
  }

  /**
   * Is called when the client has been initialized or an error occurred during
   * initialization.
   *
   * @param err - An error or null depending on whether the initialization succeeded.
   * @private
   */
  private handleInitialized(err: ErrorMsg | null) {
    const [resolve, reject] = this.initPromise!;
    if (!err) {
      resolve(null);
    } else {
      reject(err.error);
    }
    this.initPromise = undefined;
  }

  /**
   * Handles requests by calling the corresponding request handler for the
   * specific type of the PerunMessage contained in the request. The response
   * message returned by a handler is sent back to the websocket.
   *
   * @param req -
   * @private
   */
  private handleRequest(req: Request) {
    let resp: Promise<PerunMessage>;

    const reqMsg = req.message.message;
    switch (reqMsg.messageTypeName()) {
      case "ChannelProposal":
        resp = this.handleRequestType(
          "channelProposal",
          reqMsg as ChannelProposal
        );
        break;
      case "UpdateChannel":
        resp = this.handleRequestType("channelUpdate", reqMsg as UpdateChannel);
        break;
      case "SignData":
        resp = this.handleRequestType("signData", reqMsg as SignData);
        break;
      case "SendTx":
        resp = this.handleRequestType("sendTX", reqMsg as SendTx);
        break;
      default:
        console.error(
          `handleRequest: unknown message type: ${reqMsg.messageTypeName()}`
        );
        return;
    }

    resp
      .then((respMsg) => {
        this.sendMessage(new Response(req.id, new PerunObject(respMsg)));
      })
      .catch((err) => {
        const errMsg = err instanceof Error ? err.message : err.toString();
        this.sendMessage(
          new Response(req.id, new PerunObject(new ErrorMsg(errMsg)))
        );
      });
  }

  /**
   * Is called everytime a Response has been received.
   *
   * @param resp -
   */
  private handleResponse(resp: Response) {
    const reqID = resp.id;

    const respMsg = resp.message.message;

    if (!this.requestPromises.has(reqID)) {
      console.error(`received response with unknown ID: ${reqID}`);
      return;
    }

    const [resolve, reject] = this.requestPromises.get(reqID)!;
    if (respMsg.messageTypeName() === "Error") {
      reject((respMsg as ErrorMsg).error);
    } else {
      resolve(respMsg);
    }
    this.requestPromises.delete(reqID);
  }

  /**
   * Calls the handler defined for the requestType with the corresponding
   * PerunMessage.
   *
   * @param requestType -
   * @param message -
   * @private
   */
  private handleRequestType<T extends RequestType>(
    requestType: T,
    message: RequestTypeMessage<T>
  ): Promise<PerunMessage> {
    const handler = this.requestHandlers.get(requestType);
    if (!handler) {
      throw new Error(`No handler set for ${requestType}`);
    }
    return handler(message);
  }

  /**
   * Calls the handler for the channel event with the message.
   *
   * @param event -
   * @param message -
   * @private
   */
  private handleChannelEvent<T extends ChannelEvent>(
    event: T,
    message: ChannelEventMessage<T>
  ) {
    const handler = this.channelEventHandler.get(event);
    if (!handler) {
      throw new Error(`No channel event handler set for ${event}`);
    }
    handler(message);
  }

  /**
   * Handles messages for which the type is not known.
   *
   * @param ev -
   */
  private static handleUnknownMessage(ev: MessageEvent) {
    console.error(`Received unknown message: ${ev.data}`);
  }
}

/**
 * Rejects if the given promise does not complete within the given time.
 *
 * @param ms The timeout in milliseconds.
 * @param promise The promise which should be bounded with a timeout.
 * @returns Either the completion of the given promise or an error.
 */
export function promiseWithTimeout(ms: number, promise: Promise<any>) {
  const timeout = new Promise<void>((resolve, reject) => {
    const id = setTimeout(() => {
      clearTimeout(id);
      reject("Timeout exceeded");
    }, ms);
  });

  return Promise.race([promise, timeout]);
}

export function delay(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export function disconnect(conn: Connection) {
  if (conn.isConnected()) {
    conn.ws = undefined;
  }
}
