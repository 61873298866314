// SPDX-License-Identifier: Apache-2.0

import PerunMessage from "./PerunMessage";
import { jsonObject } from "typedjson";
import { TransactionRequest } from "@ethersproject/abstract-provider";
import { MessageType, MessageTypeName } from "./messageTypes";
import { jsonBigIntMember } from "./types/bigint";
import { jsonTxMember } from "./types/transaction";

@jsonObject
export default class SendTx extends PerunMessage {
  @jsonTxMember() transaction: TransactionRequest;
  @jsonBigIntMember() chainID: bigint;

  constructor(transaction: TransactionRequest, chainID: bigint) {
    super();
    this.transaction = transaction;
    this.chainID = chainID;
  }

  messageType(): MessageType {
    return SendTx;
  }

  messageTypeName(): MessageTypeName {
    return "SendTx";
  }
}
