// SPDX-License-Identifier: Apache-2.0

import ETH_LOGO from "../images/assets/ETH_logo.png";
import PRN_LOGO from "../images/assets/PRN_logo.png";
import ROPT_LOGO from "../images/assets/ROPT_logo.png";
import GOE_LOGO from "../images/assets/GOE_logo.png";
import RBY_LOGO from "../images/assets/RBY_logo.png";
import VIA_LOGO from "../images/assets/VIA_logo.png";
import BLC_LOGO from "../images/assets/BLC_logo.png";
import SEP_LOGO from "../images/assets/SEP_logo.png";
import Address from "../api/types/address";
import {
  BlockExplorer,
  BlockScout,
  Etherscan,
  Localhost,
} from "./BlockExplorer";

export const connectionTimeoutMS = 10000;
export const defaultTimeoutSeconds = 5000;
export const proposingTimeoutSeconds = 10000;
export const challengeDurationSeconds = 600;
export const amountStepWidth = 0.0001;
export const maxRejectReasonLength = 100;
export const feedbackEmail = "feedback@perun.network";
export const discordLink = "https://discord.me/perun";

const EthereumChainID = 1n;
const RopstenChainID = 3n;
const RinkebyChainID = 4n;
const GoerliChainID = 5n;
const KovanChainID = 42n;
const VivianiChainID = 133n;
const BellecourChainID = 134n;
const SepoliaChainID = 11155111n;
const Localhost1337ChainID = 1337n;
const Localhost1338ChainID = 1338n;

const EthereumCode = "ETH";
const RLCCode = "RLC";
const RopstenTokenCode = "ROPT";
const RinkebyTokenCode = "RBY";
const GoerliTokenCode = "GOE";
const GoerliETHTokenCode = "GOEETH";
const PerunTokenCode = "PRN";
const VivianiTokenCode = "VIA";
const BellecourTokenCode = "BLC";
const BellecourETHTokenCode = "xRLC";
const SepoliaTokenCode = "SEPETH";

// valid swap Pairs
export const validSwapPairs = [
  ["ETH", "RLC"],
  ["ETH", "xRLC"],
  ["ETH", "PRN"],
  ["GOE", "GOEETH"],
  ["GOE", "SEPETH"],
  ["SEPETH", "GOEETH"],
  ["GOE", "BLC"],
  ["BLC", "GOEETH"],
  ["BLC", "SEPETH"],
  ["xRLC", "RLC"],
];

export const ChainNames = new Map<bigint, string>([
  [EthereumChainID, "Ethereum"],
  [RopstenChainID, "Ropsten"],
  [RinkebyChainID, "Rinkeby"],
  [GoerliChainID, "Goerli"],
  [KovanChainID, "Kovan"],
  [VivianiChainID, "Viviani"],
  [BellecourChainID, "Bellecour"],
  [SepoliaChainID, "Sepolia"],
  [Localhost1337ChainID, "Localhost 1337"],
  [Localhost1338ChainID, "Localhost 1338"],
]);

export type Hub = {
  name: string;
  address: string;
};

export const Hubs = new Map<bigint, Hub>([
  [
    EthereumChainID,
    {
      name: "Perun Ethereum Hub #1",
      address: "0x700c07D26209E4DA5Df30cAd20D4209e707Adb80",
    },
  ],
  [
    RinkebyChainID,
    {
      name: "Perun Rinkeby Hub #1",
      address: "0x8F6881803aE364A4E6E7D44e0CC6049635266789",
    },
  ],
  [
    GoerliChainID,
    {
      name: "Perun Goerli Hub #1",
      address: "0x8F6881803aE364A4E6E7D44e0CC6049635266789",
    },
  ],
  [
    VivianiChainID,
    {
      name: "Perun Viviani Hub #1",
      address: "0x8F6881803aE364A4E6E7D44e0CC6049635266789",
    },
  ],
  [
    BellecourChainID,
    {
      name: "Perun Bellecour Hub #1",
      address: "0x700c07D26209E4DA5Df30cAd20D4209e707Adb80",
    },
  ],
  [
    SepoliaChainID,
    {
      name: "Perun Sepolia Hub #1",
      address: "0x8F6881803aE364A4E6E7D44e0CC6049635266789",
    },
  ],
  [
    Localhost1337ChainID,
    {
      name: "Perun Localhost Hub #1",
      address: "0x05e71027e7d3bd6261de7634cf50f0e2142067c4",
    },
  ],
  [
    Localhost1338ChainID,
    {
      name: "Perun Localhost Hub #1",
      address: "0x05e71027e7d3bd6261de7634cf50f0e2142067c4",
    },
  ],
]);

export const BlockExplorers = new Map<bigint, BlockExplorer>([
  [EthereumChainID, new Etherscan()],
  [RopstenChainID, new Etherscan("ropsten")],
  [RinkebyChainID, new Etherscan("rinkeby")],
  [GoerliChainID, new Etherscan("goerli")],
  [KovanChainID, new Etherscan("kovan")],
  [VivianiChainID, new BlockScout("https://blockscout-viviani.iex.ec")],
  [BellecourChainID, new BlockScout("https://blockscout-bellecour.iex.ec")],
  [SepoliaChainID, new Etherscan("sepolia")],
  [Localhost1337ChainID, new Localhost()],
  [Localhost1338ChainID, new Localhost()],
]);

export function getExplorerURL(
  chainID: bigint,
  address: Address | string,
  display: "address" | "token",
  filterAddress?: Address
): string {
  const url = BlockExplorers.get(chainID);
  if (!url) {
    throw Error("unknown chain ID");
  }

  return url.getExplorerURL(address, display, filterAddress);
}

// The assets are only identified by their code rather than AssetID because
// these constants only mock getting asset data and should not depend on the
// chainID for the moment, so we assume unique asset codes here.
export const assetLogos = new Map([
  [EthereumCode, ETH_LOGO],
  [RLCCode, ETH_LOGO],
  [PerunTokenCode, PRN_LOGO],
  [RopstenTokenCode, ROPT_LOGO],
  [GoerliTokenCode, GOE_LOGO],
  [GoerliETHTokenCode, GOE_LOGO],
  [RinkebyTokenCode, RBY_LOGO],
  [VivianiTokenCode, VIA_LOGO],
  [BellecourTokenCode, BLC_LOGO],
  [BellecourETHTokenCode, BLC_LOGO],
  [SepoliaTokenCode, SEP_LOGO],
]);
export const assetPrecisions = new Map([
  [EthereumCode, 8],
  [RLCCode, 8],
  [PerunTokenCode, 0],
  [RopstenTokenCode, 0],
  [GoerliTokenCode, 0],
  [GoerliETHTokenCode, 8],
  [RinkebyTokenCode, 0],
  [VivianiTokenCode, 0],
  [BellecourTokenCode, 0],
  [BellecourETHTokenCode, 8],
  [SepoliaTokenCode, 8],
]);

// Estimated gas used for the withdrawal.
export const ethWithdrawal = 43500;
export const erc20Withdrawal = 51328;

// Estimated gas used for the approval.
export const approval = 29794;

// Estimated gas used for the deposit.
export const ethDeposit = 45843;
export const erc20Deposit = 70279;

// Estimated gas used for concludeFinal.
export const concludeSameChain = 217526;
export const concludeCrossChain = 159909;
