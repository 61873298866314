// SPDX-License-Identifier: Apache-2.0

import PerunMessage from "./PerunMessage";
import { jsonArrayMember, jsonMember, jsonObject } from "typedjson";
import { MessageType, MessageTypeName } from "./messageTypes";
import { jsonBigIntMember } from "./types/bigint";

@jsonObject()
export class Chain {
  @jsonMember(String) name: string;
  @jsonBigIntMember() ID: bigint;

  constructor(name: string, ID: bigint) {
    this.name = name;
    this.ID = ID;
  }
}

@jsonObject()
export default class GetChainsResponse extends PerunMessage {
  @jsonArrayMember(Chain) chains: Chain[];

  constructor(chains: Chain[]) {
    super();
    this.chains = chains;
  }

  messageType(): MessageType {
    return GetChainsResponse;
  }

  messageTypeName(): MessageTypeName {
    return "GetChainsResponse";
  }
}
