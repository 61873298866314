// SPDX-License-Identifier: Apache-2.0

import React from "react";
import "./i18n/i18n";
import "./App.css";
import logo from "./images/perun_logo.png";
import { Nav, Navbar, NavLink } from "react-bootstrap";
import { Onboarding } from "./components/Onboarding";
import { AppContext, PerunAppState } from "./AppContext";
import InfoModal, { InfoProps } from "./components/dialogs/InfoModal";
import { Client } from "./client/Client";
import { PerunNotice } from "./events/Notice";
import { useTranslation } from "react-i18next";
import { HelpMenu } from "./components/HelpMenu";
import PrivacyPolicy from "./components/PrivacyPolicy";
import SwapScreen from "./components/SwapScreen";
import History from "./components/History";
import ErrorBoundary from "./components/ErrorBoundary";

type Page = "ONBOARDING" | "SWAP" | "HISTORY";
import Contact from "./components/Contact";
import ErrorModal, { ErrorProps } from "./components/dialogs/ErrorModal";
import ContactInfo from "./components/ContactInfo";
import { dispatchMetamaskError } from "./events/Error";

function App(this: any) {
  const { t } = useTranslation();
  const [page, setPage] = React.useState<Page>("ONBOARDING");
  // We use a separate state for the show property of InfoProps to prevent the
  // info modal from updating to the new content first before actually hiding.
  const [hasInfo, setShowInfo] = React.useState(false);
  const [hasError, setShowError] = React.useState(false);
  const [infoProps, setInfoProps] = React.useState<InfoProps>({
    show: false,
    toggleShow: () => {},
  });
  const [constInfoProps, setConstInfoProps] = React.useState<ErrorProps>({
    show: false,
  });
  const [client, setClient] = React.useState<Client>();

  const handleError = (ev: CustomEventInit<JSX.Element>) => {
    setInfoProps({
      show: hasInfo,
      toggleShow: () => setShowInfo(false),
      title: t("error"),
      content: ev.detail,
    });
    setShowInfo(true);
  };

  const handleOpenError = (ev: CustomEventInit<JSX.Element>) => {
    setConstInfoProps({
      show: hasError,
      title: t("error"),
      content: ev.detail,
    });
    setShowError(true);
  };

  const handleCloseError = (ev: CustomEventInit<JSX.Element>) => {
    setShowError(false);
  };

  const handleNotice = (ev: CustomEventInit<PerunNotice>) => {
    setInfoProps({
      show: hasInfo,
      toggleShow: () => setShowInfo(false),
      title: ev.detail?.title,
      content: ev.detail?.content,
      submitText: ev.detail?.submitText,
      onSubmit: ev.detail?.onSubmit,
      cancelText: ev.detail?.cancelText,
      onCancel: ev.detail?.onCancel,
      autoClose: ev.detail?.autoClose,
    });
    setShowInfo(true);
  };

  const handleCloseNotice = (ev: CustomEventInit<JSX.Element>) => {
    setShowInfo(false);
  };

  const initClient = (client: Client) => {
    setClient(client);
  };

  const ctx = {
    client: client,
    setClient: initClient,
  } as PerunAppState;

  React.useEffect(() => {
    document.addEventListener("PerunError", handleError);
    document.addEventListener("PerunNotice", handleNotice);
    document.addEventListener("ClosePerunNotice", handleCloseNotice);
    document.addEventListener("OpenMetamaskError", handleOpenError);
    document.addEventListener("CloseMetamaskError", handleCloseError);
    return () => {
      document.removeEventListener("PerunError", handleError);
      document.removeEventListener("PerunNotice", handleNotice);
      document.removeEventListener("ClosePerunNotice", handleCloseNotice);
      document.removeEventListener("OpenMetamaskError", handleOpenError);
      document.removeEventListener("CloseMetamaskError", handleCloseError);
    };
  });

  const getPage = (): JSX.Element => {
    switch (page) {
      case "ONBOARDING":
        localStorage.setItem("site", "ONBOARDING");
        return (
          <Onboarding
            toggleOnboarded={() => {
              setPage("SWAP");
            }}
          />
        );
      case "SWAP":
        if (
          window.localStorage.getItem("site") === "HISTORY" &&
          ctx.client.provider.getChainID.toString() !==
            window.localStorage.getItem("chainID")
        ) {
          dispatchMetamaskError(
            <p>
              {t("errorUnexpectedNetwork", {
                currID: ctx.client.provider.getChainID.toString(),
                chainID: window.localStorage.getItem("chainID"),
              })}
            </p>
          );
        }
        localStorage.setItem("site", "SWAP");
        return <SwapScreen />;
      case "HISTORY":
        localStorage.setItem("site", "HISTORY");
        return <History />;
    }
  };

  return (
    <>
      <ErrorBoundary>
        <Navbar className="navbar sticky-top">
          <Navbar.Brand
            onClick={() => {
              if (page !== "ONBOARDING") {
                setPage("SWAP");
              }
            }}
            href="#"
            style={{ color: "#00C9B8" }}
          >
            <img id={"perun-logo"} width="30" src={logo} alt="" />
            <span className="d-none d-sm-inline navbar-brand">
              {t("title")}
            </span>
          </Navbar.Brand>

          {
            <Nav>
              <NavLink
                active={page === "ONBOARDING"}
                onClick={() => {
                  window.localStorage.removeItem("site");
                  window.localStorage.removeItem("chainID");
                  ctx.client.shutdownClient();
                  setPage("ONBOARDING");
                }}
              >
                {t("onboard")}
              </NavLink>
              <NavLink
                disabled={page === "ONBOARDING"}
                active={page === "SWAP"}
                onClick={() => {
                  setPage("SWAP");
                }}
              >
                {t("swap")}
              </NavLink>
              <NavLink
                disabled={page === "ONBOARDING"}
                active={page === "HISTORY"}
                onClick={() => setPage("HISTORY")}
              >
                {t("history")}
              </NavLink>
            </Nav>
          }

          <Nav className={"ml-auto"}>
            <AppContext.Provider value={ctx}>
              <HelpMenu />
            </AppContext.Provider>
            <div className={"StatusPane"}>
              {page !== "ONBOARDING" ? t("connected") : t("notConnected")}
            </div>
          </Nav>
        </Navbar>
        <div className="App">
          <AppContext.Provider value={ctx}>{getPage()}</AppContext.Provider>
        </div>
        <div className="flex scroll">
          <PrivacyPolicy />
          <ContactInfo />
          <div className="BottomLeft">
            <Contact />
            <p className="first-child">{t("TokenSwap")}</p>
            <p>{t("Blockchains")}</p>
          </div>
        </div>
        <InfoModal
          show={hasInfo}
          toggleShow={infoProps.toggleShow}
          title={infoProps.title}
          content={infoProps.content}
          submitText={infoProps.submitText}
          onSubmit={infoProps.onSubmit}
          cancelText={infoProps.cancelText}
          onCancel={infoProps.onCancel}
          autoClose={infoProps.autoClose}
        />
        <ErrorModal
          show={hasError}
          title={constInfoProps.title}
          content={constInfoProps.content}
        />
      </ErrorBoundary>
    </>
  );
}

export default App;
