import { jsonMember } from "typedjson";
import {
  TransactionRequest,
  TransactionResponse,
} from "@ethersproject/abstract-provider";
import { utils } from "ethers";
import { BigNumber } from "@ethersproject/bignumber";

export function jsonTxMember(): PropertyDecorator {
  return jsonMember({
    serializer: (val: TransactionResponse) => {
      return {
        to: val.to,
        accessList: val.accessList,
        chainId: toHex(val.chainId),
        nonce: toHex(val.nonce),
        gasPrice: toHex(val.gasPrice),
        maxPriorityFeePerGas: toHex(val.maxPriorityFeePerGas),
        maxFeePerGas: toHex(val.maxFeePerGas),
        gas: toHex(val.gasLimit),
        value: toHex(val.value),
        input: utils.hexlify(val.data),
        r: toHex(val.r),
        s: toHex(val.s),
        v: toHex(val.v),
        type: toHex(val.type),
      };
    },
    deserializer: (val) => {
      const tr: TransactionRequest = {
        to: val.to,
        nonce: BigNumber.from(val.nonce),
        data: utils.arrayify(val.input),
        value: BigNumber.from(val.value),
      };
      return tr;
    },
  });
}

export function jsonTxResponseMember(): PropertyDecorator {
  return jsonMember({});
}

/**
 * Converts the value to a hex representation with leading "0x" and without a
 * leading 0 in the hex value s.t. it is compatible with go-ethereum.
 * @param val
 */
function toHex(
  val: string | number | BigNumber | undefined | null
): String | undefined {
  if (val === undefined || val === null) {
    return undefined;
  }

  let hexStr;

  switch (typeof val) {
    case "string":
      hexStr = BigInt(val).toString(16);
      break;
    case "number":
      hexStr = val.toString(16);
      break;
    default:
      hexStr = val.toBigInt().toString(16);
  }

  return "0x" + hexStr;
}
