// SPDX-License-Identifier: Apache-2.0

import { ethers } from "ethers";
import detectEthereumProvider from "@metamask/detect-provider";
import i18n from "i18next";

interface MetaMaskError {
  code: number;
}

/**
 * @returns a promise for a Web3Provider initialized with the MetaMask provider
 * if it is available. Will reject if MetaMask is not detected.
 */
export async function initWeb3(): Promise<ethers.providers.Web3Provider> {
  const prov = await detectEthereumProvider({ mustBeMetaMask: true });
  if (prov) {
    return new ethers.providers.Web3Provider(
      prov as ethers.providers.ExternalProvider,
      "any"
    );
  } else {
    return Promise.reject("MetaMask not available");
  }
}

/**
 * Gets the Web3 provider that has to be connected to one of the given
 * networkIDs and requests an account.
 *
 * @param networkIDs - The IDs of the supported networks that MetaMask can be
 * connected to.
 * @returns - The Web3 Provider and the address of the provided account.
 * @throws - Error, if:
 * - Getting the Web3 Provider fails.
 * - MetaMask is not connected to one of the networkIDs.
 * - Requesting the accounts failed.
 */
export async function onboard(networkIDs: bigint[]): Promise<{
  provider: ethers.providers.Web3Provider;
  account: string;
}> {
  console.log("Onboarding...");
  let web3Provider;

  try {
    web3Provider = await initWeb3();
  } catch (e) {
    throw new Error(i18n.t("errorNoMetaMask"));
  }
  const ethereum = web3Provider.provider! as any;

  if (!ethereum) {
    throw new Error(i18n.t("errorNoMetaMask"));
  }
  if (!ethereum.isConnected) {
    throw new Error(i18n.t("errorNetNotConnected"));
  }
  const cID = await ethereum.request({
    method: "eth_chainId",
    params: [],
  });
  if (!networkIDs.includes(BigInt(cID))) {
    throw new Error(i18n.t("errorWrongNetwork"));
  }

  let accounts: string[] = [];
  try {
    await web3Provider.provider.request!({
      method: "eth_requestAccounts",
    }).then((accs: string[]) => {
      accounts = accs;
      if (accounts.length === 0) {
        return Promise.reject("No accounts specified");
      }
    });
  } catch {
    throw new Error(i18n.t("rejectedAccountSelect"));
  }

  return { provider: web3Provider, account: accounts[0] };
}

/**
 * Requests the provider to switch the network to the one given by the chainID.
 *
 * @param provider - The Web3 Provider.
 * @param chainID - The ID of the network we want the provider to switch to.
 * @throws - An Error, if the request fails.
 */
export async function switchNetwork(
  provider: ethers.providers.Web3Provider,
  chainID: bigint
): Promise<boolean> {
  let switched = false;
  try {
    await provider.provider.request?.({
      method: "wallet_switchEthereumChain",
      params: [{ chainId: "0x" + chainID.toString(16) }],
    });
    switched = true;
    return switched;
  } catch (error: unknown) {
    if (!(error && (error as MetaMaskError).code)) {
      return switched;
    }
    const mmError = error as MetaMaskError;

    switch (mmError.code) {
      case 4902:
        throw new Error(i18n.t("errorNetworkNotAvailable"));
      case 4001:
        return switched;
      default:
        throw new Error(i18n.t("errorSwitchingNetwork"));
    }
  }
}
