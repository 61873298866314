// SPDX-License-Identifier: Apache-2.0

import PerunMessage from "./PerunMessage";
import { jsonMember, jsonObject } from "typedjson";
import Address from "./types/address";
import { MessageType, MessageTypeName } from "./messageTypes";

@jsonObject
export default class SignData extends PerunMessage {
  @jsonMember(Address) address: Address;
  @jsonMember(String) data: string;

  constructor(address: Address, data: string) {
    super();
    this.address = address;
    this.data = data;
  }

  messageType(): MessageType {
    return SignData;
  }

  messageTypeName(): MessageTypeName {
    return "SignData";
  }
}
