// SPDX-License-Identifier: Apache-2.0

import { Connection, disconnect, promiseWithTimeout } from "../wire/connection";
import OpenChannel from "../api/OpenChannel";
import { ethers, utils } from "ethers";
import SignData from "../api/SignData";
import Channel from "../channel/Channel";
import Success from "../api/Success";
import SendTx from "../api/SendTx";
import SignResponse from "../api/SignResponse";
import ProposalResponse from "../api/ProposalResponse";
import {
  TransactionRequest,
  TransactionResponse,
} from "@ethersproject/abstract-provider";
import State from "./State";
import Proposal from "../channel/Proposal";
import GetChannelInfo from "../api/GetChannelInfo";
import ChannelInfo from "../api/ChannelInfo";
import CloseChannel from "../api/CloseChannel";
import {
  challengeDurationSeconds,
  defaultTimeoutSeconds,
} from "../constants/Constants";
import { getRejectReason, Rejected } from "../api/RejectReason";
import ChannelID from "../api/types/channelID";
import Provider from "./Provider";
import GetAssetsResponse from "../api/GetAssetsResponse";
import GetAssets from "../api/GetAssets";
import Swap, { SwapID } from "../channel/Swap";
import ChannelState, { ChannelAsset } from "../api/types/channelState";
import UpdateChannel from "../api/UpdateChannel";
import Address from "../api/types/address";
import PerunError from "../api/Error";
import {
  finalizingSwapNotice,
  dispatchPerunNotice,
  revertPerunNotice,
  initiateSwapNotice,
  performingSwapNotice,
} from "../events/Notice";
import i18n from "i18next";
import SendTxResponse from "../api/SendTxResponse";
import GetChainsResponse from "../api/GetChainsResponse";
import GetChains from "../api/GetChains";
import SetAdjTxSender from "../api/SetAdjTxSender";
import GetDecimals from "../api/GetDecimals";
import GetDecimalsResponse from "../api/GetDecimalsResponse";
import GetTimeout, { TimeoutType } from "../api/GetTimeout";
import GetTimeoutResponse from "../api/GetTimeoutResponse";
import GetQuote from "../api/GetQuote";
import GetQuoteResponse from "../api/GetQuoteResponse";
import GetSignedState from "../api/GetSignedState";
import SignedState from "../api/SignedState";
import Allocation, { Params, SState } from "../api/types/channelSignedState";
import ShutdownClient from "../api/ShutdownClient";

/**
 * Represents the client which communicates with the websocket and is able to
 * create signatures using a Web3Provider.
 */
export class Client {
  public state: State;
  public initialized = false;
  private conn: Connection;
  readonly provider: Provider;

  constructor(provider: Provider, conn: Connection) {
    this.state = new State();
    this.conn = conn;
    this.provider = provider;

    this.initHandlers();
  }

  /**
   * Initializes the client on the websocket by connecting to it and sending its
   * own address.
   *
   * @returns A Promise with a boolean for whether the client has been
   * successfully initialized.
   */
  public async initializeConn(): Promise<boolean> {
    if (this.initialized) {
      return Promise.reject("client is already initialized");
    }

    await this.conn.connect();
    try {
      await promiseWithTimeout(
        defaultTimeoutSeconds,
        this.conn.initialize(this.provider.getAddress())
      );
    } catch (err) {
      console.error(err);
      return false;
    }
    this.initialized = true;
    return this.initialized;
  }

  public shutdownClient() {
    if (this.initialized) {
      this.conn.request(new ShutdownClient(this.provider.getAddress()));
      disconnect(this.conn);
      this.initialized = false;
    }
  }

  public getChainID(): Promise<bigint> {
    return this.provider.getChainID();
  }

  public async getChains(): Promise<GetChainsResponse> {
    return this.conn.request(new GetChains()).then((resp) => {
      return resp as GetChainsResponse;
    });
  }

  /**
   * Queries the backend for assets on chains with the matching chain ID.
   *
   * @param chainIDQuery The type of chain IDs that should be used for the
   * query.
   * Either "custom" for defining a list of IDs by "ID" manually,
   * "own" referring to the ID of the currently connected chain,
   * or "all" for asking for all assets on all chains.
   * @returns
   */
  public async getAssets(
    chainIDQuery: ChainIDQuery
  ): Promise<GetAssetsResponse> {
    let chainIDs = Array<bigint>();
    switch (chainIDQuery.type) {
      case "custom":
        chainIDs = chainIDQuery.IDs;
        break;
      case "own":
        chainIDs = [await this.provider.getChainID()];
        break;
      case "all":
        (await this.getChains()).chains.map((c) => chainIDs.push(c.ID));
        break;
    }
    return this.conn.request(new GetAssets(chainIDs)).then((resp) => {
      return resp as GetAssetsResponse;
    });
  }

  public async getDecimals(asset: ChannelAsset): Promise<number | undefined> {
    return this.conn
      .request(new GetDecimals(asset))
      .then((resp) => {
        return (resp as GetDecimalsResponse).decimals;
      })
      .catch((err) => {
        if (err instanceof PerunError) {
          console.error(`getDecimals: ${err.error}`);
        } else {
          console.error(`getDecimals: ${err}`);
        }
        return undefined;
      });
  }

  public async getQuote(
    fromAsset: ChannelAsset,
    toAsset: ChannelAsset,
    hubAddress: Address
  ): Promise<GetQuoteResponse | undefined> {
    return this.conn
      .request(new GetQuote(fromAsset, toAsset, hubAddress))
      .then((resp) => {
        return resp as GetQuoteResponse;
      })
      .catch((err) => {
        if (err instanceof PerunError) {
          console.error(`getQuote: ${err.error}`);
        } else {
          console.error(`getQuote: ${err}`);
        }
        return undefined;
      });
  }

  public async getTimeout(
    timeoutType: TimeoutType
  ): Promise<number | undefined> {
    return this.conn
      .request(new GetTimeout(timeoutType))
      .then((resp) => {
        return (resp as GetTimeoutResponse).timeout;
      })
      .catch((err) => {
        if (err instanceof PerunError) {
          console.error(`getTimeout: ${err.error}`);
        } else {
          console.error(`getTimeout: ${err}`);
        }
        return undefined;
      });
  }

  public async getSignedState(channelID: ChannelID): Promise<SignedState> {
    return this.conn.request(new GetSignedState(channelID)).then((resp) => {
      return resp as SignedState;
    });
  }

  public async sendSignedState(
    signedState: SignedState
  ): Promise<Success | Error> {
    return this.conn
      .request(
        new SignedState(signedState.params, signedState.state, signedState.sigs)
      )
      .then((resp) => {
        return resp as Success | Error;
      });
  }

  public async initiateSwap(swap: Swap) {
    this.state.addSwap(swap);

    const chState = new ChannelState(
      swap.channelAssets,
      swap.myBalances,
      swap.peerBalances
    );
    const prop = new Proposal(swap.ID, swap.peerAddress, chState);

    const submit = async () => {
      console.log("requesting the peer as the adjudicator transaction sender");
      await this.setAdjTxSender(swap).catch((err) => console.error(err));

      console.log(`sending channel proposal ${swap.ID}`);
      await this.openChannel(prop)
        .then((resp) => {
          if (resp instanceof Success) {
            console.log("channel created");
            swap.status = "channelCreated";
            this.state.emitUpdateSwap(swap.ID);
          } else {
            swap.status = "proposalRejected";
            swap.rejectionReason = resp.reason;
            this.state.emitUpdateSwap(swap.ID);
          }
        })
        .catch((err) => {
          swap.error = err;
          this.state.emitUpdateSwap(swap.ID);
        });
    };

    const cancel = () => {
      swap.error = new Error(i18n.t("errorCanceledSwapInit"));
      this.state.emitUpdateSwap(swap.ID);
    };

    dispatchPerunNotice(
      initiateSwapNotice(
        swap.fromAsset.name,
        swap.fromAsset.type,
        submit,
        cancel
      )
    );
  }

  private async saveSignedState(swap: Swap, update: boolean) {
    if (!swap.channelID) {
      throw new Error("cannot perform swap with non-underlying channel");
    }
    let signedState = new SignedState(
      new Params([], 0, [], "", "", "", true, false),
      new SState([], 0, "", "", new Allocation([], [], []), "", false),
      []
    );
    await this.getSignedState(swap.channelID!).then((res) => {
      if (res !== undefined) {
        signedState = res!;
      } else {
        signedState = new SignedState(
          new Params([], 0, [], "", "", "", true, false),
          new SState([], 0, "", "NoApp", new Allocation([], [], []), "", false),
          []
        );
      }
    });
    //update contols the submit function, if saveSignedState was called after updateChannel we do not want to call performSwap again.
    if (update) {
      dispatchPerunNotice(finalizingSwapNotice(JSON.stringify(signedState)));
    } else {
      dispatchPerunNotice(performingSwapNotice(JSON.stringify(signedState)));
    }
  }

  //send back signed state, needs SendSignedState message in ws backend
  public async sendChannelSignedState(state: SignedState) {
    try {
      await this.sendSignedState(state).then((res) => {
        if (res instanceof Success) {
          return "success";
        } else {
          return "error";
        }
      });
    } catch (err) {
      return "error";
    }
  }

  private performSwap(swap: Swap) {
    if (!swap.channelID) {
      throw new Error("cannot perform swap with non-underlying channel");
    }
    // The proposed new state corresponds to swapping the balances and
    // setting it as final.
    const newState = new ChannelState(
      swap.channelAssets,
      swap.peerBalances,
      swap.myBalances,
      true
    );
    dispatchPerunNotice(performingSwapNotice());
    this.sendUpdate(swap.channelID!, newState, swap.ID).then((res) => {
      if (res instanceof Success) {
        console.log(`swap update complete ${swap.ID}`);
        swap.status = "closingChannel";
        this.state.emitUpdateSwap(swap.ID);
        return this.finalizeSwap(swap);
      } else {
        swap.status = "updateRejected";
        swap.rejectionReason = res.reason;
        this.state.emitUpdateSwap(swap.ID);
      }
    });
  }

  private finalizeSwap(swap: Swap) {
    if (!swap.channelID) {
      throw new Error("cannot finalize swap with non-underlying channel");
    }

    const channel = this.state.getChannel(swap.channelID)!;
    if (!channel) {
      throw new Error("finalize swap: channel not found");
    }
    if (!channel.state.isFinal) {
      throw new Error("finalize swap expects the channel state to be final");
    }

    dispatchPerunNotice(finalizingSwapNotice());
    this.closeChannel(channel.id, "default", swap.withdrawalAddress)
      .then((res) => {
        if (res instanceof Success) {
          console.log(`swap is finalized ${swap.ID}`);
          swap.status = "complete";
          this.state.emitUpdateSwap(swap.ID);
        } else {
          // We can't get a Rejected Message since the channel's state was final.
          swap.error = new Error("closing has failed due to unknown reasons");
          throw new Error(`finalizeSwap: expected Success, got ${res}`);
        }
      })
      .catch((err) => {
        swap.error = err;
      });
  }

  /**
   * Sets the adjudicator transaction sender for a swap.
   * This is especially necessary if we perform a cross-chain swap where the
   * user cannot send transactions on other chains that the connected one.
   * If setting the sender fails, it updates the swap status.
   *
   * @param swap
   * @throws - Error if it could not set the adjudicator Tx sender.
   */
  private async setAdjTxSender(swap: Swap) {
    // setAdjTxSender calls the client's setAdjTxSender method and on error,
    // updates the swap status and throws an error.
    const setAdjTxSender = async (chainID: bigint) => {
      const resp = await this.conn
        .request(new SetAdjTxSender(chainID, swap.peerAddress))
        .catch((err: string) => {
          console.error(err);
          return new PerunError(err);
        });
      if (resp instanceof PerunError) {
        swap.status = "noAdjTxSender";
        this.state.emitUpdateSwap(swap.ID);
        throw Error("could not set the adjudicator Tx sender");
      }
    };

    try {
      await setAdjTxSender(swap.fromAsset.chainID);
      // If we do a cross-chain swap, we need to set the adjudicator Tx sender
      // for both chains.
      if (swap.fromAsset.chainID !== swap.toAsset.chainID) {
        await setAdjTxSender(swap.toAsset.chainID);
      }
    } catch (e) {
      console.error(e);
      return;
    }
  }

  /**
   * Sends a channel proposal to the given peer address.
   *
   * @param proposal -
   * @returns A promise for the success or rejection of the proposal.
   */
  private openChannel(proposal: Proposal): Promise<Success | Rejected> {
    const openMsg = new OpenChannel(
      proposal.id,
      proposal.peerAddress,
      BigInt(challengeDurationSeconds),
      proposal.state
    );

    return this.conn.request(openMsg).catch((err) => {
      const reason = getRejectReason(err, "channel proposal");
      if (reason) {
        return reason;
      } else {
        return { reason: err };
      }
    });
  }

  private sendUpdate(
    channelID: ChannelID,
    newState: ChannelState,
    swapID: SwapID
  ): Promise<Success | Rejected> {
    const channel = this.state.getChannel(channelID);
    if (!channel) {
      throw new Error(`sendUpdate: unknown channel ${channelID}`);
    }
    return this.conn
      .request(new UpdateChannel(channelID, newState))
      .then((msg) => {
        // Update the state of the channel.
        channel.updateState(newState);
        this.saveSignedState(this.state.getSwap(swapID)!, true);
        // Fixme: Check if necessary.
        this.state.updateChannel(channel);
        return msg as Success;
      })
      .catch((err) => {
        const reason = getRejectReason(err, "channel update");
        if (reason) {
          return reason;
        } else {
          throw new Error(err);
        }
      });
  }

  public async forceCloseSwap(swapID: SwapID) {
    const swap = this.state.getSwap(swapID);
    if (!swap) {
      throw Error("swap not found");
    }
    if (!swap.channelID) {
      throw Error("swap has no underlying channel yet");
    }

    swap.status = "forceClosing";
    this.state.emitUpdateSwap(swap.ID);

    await this.closeChannel(swap.channelID, "force")
      .then(() => {
        swap.status = "forceClosed";
        this.state.emitUpdateSwap(swap.ID);
      })
      .catch((err) => {
        console.error(`force closing channel: ${err}`);
        swap.status = "failedClosing";
        swap.error = err;
        this.state.emitUpdateSwap(swap.ID);
      });
  }

  /**
   * Sends a close channel request for the channel with the given channel ID.
   *
   * @param chID -
   * @param type - The closing type, either default or by forcing the channel
   * closing (registers a non-final state).
   * @param withdrawalAddress - The address to which the funds should be
   * withdrawn. If undefined, uses the client's address.
   * @returns - A promise for the success or rejection of the channel closing
   * request.
   */
  private closeChannel(
    chID: ChannelID,
    type: "default" | "force" = "default",
    withdrawalAddress?: Address
  ): Promise<Success | Rejected> {
    return this.conn
      .request(new CloseChannel(chID, type === "force", withdrawalAddress))
      .catch((err) => {
        const reason = getRejectReason(err, "channel update");
        if (reason) {
          return reason;
        } else {
          throw Error(err);
        }
      });
  }

  /**
   * Signs the given message using the Web3Provider and returns the signature.
   * @param message -
   * @returns A promise for the signature.
   * @protected
   */
  protected signMessage(message: string | ethers.utils.Bytes): Promise<string> {
    try {
      return this.provider.signMessage(utils.arrayify(message));
    } catch (e) {
      console.error(e);
      throw Error("could not sign message");
    }
  }

  /**
   * Sends the given transaction using the Web3Provider and returns the
   * transaction response.
   * @param transaction The TransactionRequest which should have set the chainID.
   * @returns A promise for the TransactionResponse.
   * @protected
   */
  private sendTransaction(
    transaction: TransactionRequest
  ): Promise<TransactionResponse> {
    return this.provider.sendTransaction(transaction);
  }

  private initHandlers() {
    // Handle new blocks and check if the balance changed.
    this.provider.onBlock((blockNumber) => {
      this.provider.getETHBalance(blockNumber).then((newBal) => {
        const oldBal = this.state.getBalance();
        if (!oldBal || oldBal.valueOf() !== newBal) {
          this.state.updateBalance(newBal);
        }
      });
    });

    // Set the proposal handler.
    this.conn.onRequest("channelProposal", (prop) => {
      console.log("Received channel proposal");
      return Promise.resolve(
        new ProposalResponse(
          false,
          "X-Chain clients do not support incoming channel proposals"
        )
      );
    });

    // Set the handler for channelCreated events which creates a new channel
    // object and adds it to the client's state channels list.
    this.conn.onChannelEvent("channelCreated", async (msg) => {
      const chID = msg.id;
      console.log(`Channel created ${chID} for ${msg.proposalID}`);
      const chInfo = (await this.conn.request(
        new GetChannelInfo(chID)
      )) as ChannelInfo;

      const channel = new Channel(chID, chInfo.peerAddress, chInfo.state);

      this.state.addChannel(channel);

      const swap = this.state.getSwap(msg.proposalID);
      if (!swap) {
        console.error(`Created channel for unknown proposal ${msg.proposalID}`);
        return;
      }

      swap.channelID = chID;
      console.log("update swap");
      this.state.emitUpdateSwap(swap.ID);

      this.saveSignedState(swap, false);
      this.performSwap(swap);
    });

    // The handler for channelClosed events which removes the closed channel
    // from the channels list.
    this.conn.onChannelEvent("channelClosed", async (msg) => {
      console.log(`channel closed ${msg.id}`);
      if (this.state.getChannel(msg.id)) {
        this.state.removeChannel(msg.id);
      }
    });

    this.conn.onChannelEvent("fundingError", async (msg) => {
      console.error(`received funding error: ${msg.error}`);
      const swap = this.state.getSwap(msg.proposalID);
      if (!swap) {
        console.error(`Created channel for unknown proposal ${msg.proposalID}`);
        return;
      }
      swap.channelID = msg.channelID;
      swap.status = "fundingFailed";
      swap.error = new Error(msg.error);
      this.state.emitUpdateSwap(swap.ID);
    });

    // Sets the update handler which sets the update proposal and a proposal
    // responder to the corresponding channel.
    this.conn.onRequest("channelUpdate", (msg) => {
      // TODO: Maybe at least accept final states with balance staying the same.
      return Promise.resolve(
        new ProposalResponse(
          false,
          "X-Chain clients do not support incoming channel updates"
        )
      );
    });

    try {
      // Set the handler for signData requests.
      this.conn.onRequest("signData", (msg) => this.handleSignData(msg));
    } catch (err: any) {
      console.log(err);
    }
    try {
      // Set the handler for signTransaction requests.
      this.conn.onRequest("sendTX", (msg) => this.handleSendTx(msg));
    } catch (err: any) {
      console.log(err);
    }
  }

  /**
   * Handles SignData requests by responding with a corresponding signature.
   *
   * @param msg - The SignData message containing the data to be signed.
   * @returns
   * @private
   */
  private async handleSignData(
    msg: SignData
  ): Promise<SignResponse | PerunError> {
    console.log("handleSignData");
    const buf = Buffer.from(msg.data, "base64");
    const hash = utils.keccak256(buf);
    let sig: string;
    try {
      console.log("signing", hash);
      sig = await this.signMessage(hash);
    } catch (err) {
      console.error("signing failed", err);
      throw Error("handleSignData failed");
    }
    revertPerunNotice();
    return new SignResponse(utils.arrayify(sig));
  }

  /**
   * Handles SendTx requests by sending the transaction and responding
   * with the signature of the transaction response.
   *
   * @param msg - The SendTx message containing the transaction.
   * @returns
   * @private
   */
  private async handleSendTx(
    msg: SendTx
  ): Promise<SendTxResponse | PerunError> {
    try {
      console.log("received SendTx");
      console.log(msg.transaction);

      if (msg.chainID !== (await this.getChainID())) {
        console.error(`received Tx for a different chain ID ${msg.chainID}.`);
        return new PerunError("handleSendTX failed");
      }

      const txResponse = await this.sendTransaction(
        msg.transaction as TransactionRequest
      );

      console.log(`Sent transaction ${txResponse.hash}`);
      console.log(txResponse);

      revertPerunNotice();
      return new SendTxResponse(txResponse);
    } catch (err) {
      console.error("Error in sending transaction:", err);
      return new PerunError("handleSendTX failed");
    }
  }
}

export type ChainIDQuery =
  | {
      type: "custom";
      IDs: bigint[];
    }
  | { type: "own" }
  | { type: "all" };
