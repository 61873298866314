// AssetDropdown.tsx

import React from "react";
import { Dropdown, DropdownButton } from "react-bootstrap";
import { getTokenTitle, validPair } from "./AmountAndAssetForm";
import Asset from "../../api/types/asset";

interface AssetDropdownProps {
  assets: Map<String, Asset>;
  selectedAsset?: String;
  otherAsset?: String;
  chainID?: BigInt;
  onSelectAsset: (assetID: string | null) => void;
}

const AssetDropdown: React.FC<AssetDropdownProps> = ({
  assets,
  selectedAsset,
  otherAsset,
  chainID,
  onSelectAsset,
}) => {
  return (
    <DropdownButton
      id={"asset-button"}
      className={"btn-block"}
      variant="outline-secondary"
      title={
        selectedAsset && assets.has(selectedAsset)
          ? getTokenTitle(assets.get(selectedAsset)!, false, true)
          : "Select Token"
      }
      onSelect={onSelectAsset}
    >
      {chainID && (
        <>
          {Array.from(assets.values())
            .filter((a) => a.chainID === chainID)
            .map((a) => (
              <Dropdown.Item
                key={a.assetID()}
                eventKey={a.assetID()}
                disabled={
                  otherAsset
                    ? a.assetID() === otherAsset ||
                      !validPair(a, assets.get(otherAsset!)!)
                    : false
                }
              >
                {getTokenTitle(a, true, false)}
              </Dropdown.Item>
            ))}
        </>
      )}
      {!chainID && (
        <>
          {Array.from(assets.values()).map((a) => (
            <Dropdown.Item
              key={a.assetID()}
              eventKey={a.assetID()}
              disabled={
                otherAsset
                  ? a.assetID() === otherAsset ||
                    !validPair(a, assets.get(otherAsset!)!)
                  : false
              }
            >
              {getTokenTitle(a, true, false)}
            </Dropdown.Item>
          ))}
        </>
      )}
    </DropdownButton>
  );
};

export default AssetDropdown;
