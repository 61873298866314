// SPDX-License-Identifier: Apache-2.0

import PerunMessage from "./PerunMessage";
import { jsonMember, jsonObject } from "typedjson";
import { MessageType, MessageTypeName } from "./messageTypes";
import ChannelID from "./types/channelID";
import ProposalID from "./types/poposalID";

@jsonObject
export default class FundingError extends PerunMessage {
  @jsonMember(ProposalID) proposalID: ProposalID;
  @jsonMember(ChannelID) channelID: ChannelID;
  @jsonMember(String) error: string;

  constructor(proposalID: ProposalID, channelID: ChannelID, error: string) {
    super();
    this.proposalID = proposalID;
    this.channelID = channelID;
    this.error = error;
  }

  messageType(): MessageType {
    return FundingError;
  }

  messageTypeName(): MessageTypeName {
    return "FundingError";
  }
}
