// SPDX-License-Identifier: Apache-2.0

import { jsonArrayMember, jsonMember } from "typedjson";

export function jsonBigIntMember(): PropertyDecorator {
  return jsonMember({
    deserializer: (json) =>
      json === null || json === undefined ? json : BigInt(json),
    serializer: (value) =>
      value === null || value === undefined ? value : value.toString(),
  });
}

export function jsonBigIntArrayMember(): PropertyDecorator {
  return jsonArrayMember(BigInt, {
    deserializer: (json) => {
      if (json === null || json === undefined) {
        return json;
      }
      const jsonArr = json as string[];
      const bigIntArr = new Array<BigInt>(jsonArr.length);
      jsonArr.forEach((v, i) => (bigIntArr[i] = BigInt(v)));

      return bigIntArr;
    },
    serializer: (value) => {
      if (value === null || value === undefined) {
        return value;
      }
      const bigIntArr = value as bigint[];
      const jsonArr = new Array<string>(bigIntArr.length);
      bigIntArr.forEach((v, i) => (jsonArr[i] = v.toString()));

      return jsonArr;
    },
  });
}
