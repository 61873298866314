// SPDX-License-Identifier: Apache-2.0

import { jsonMember, jsonObject } from "typedjson";
import Address from "./types/address";
import PerunMessage from "./PerunMessage";
import { MessageType, MessageTypeName } from "./messageTypes";

@jsonObject
export default class ShutdownClient extends PerunMessage {
  @jsonMember(Address) clientAddress: Address;

  constructor(clientAddress: Address) {
    super();
    this.clientAddress = clientAddress;
  }

  messageType(): MessageType {
    return ShutdownClient;
  }

  messageTypeName(): MessageTypeName {
    return "ShutdownClient";
  }
}
