import ProposalID from "../api/types/poposalID";
import Address from "../api/types/address";
import Asset from "../api/types/asset";
import ChannelID from "../api/types/channelID";
import { jsonArrayMember, jsonMember, jsonObject } from "typedjson";
import { jsonBigIntArrayMember } from "../api/types/bigint";
import { ChannelAsset } from "../api/types/channelState";

export type SwapID = ProposalID;

/**
 * The current status of the Swap.
 *
 * Is initially pendingProposal until the peer accepted or rejected to it.
 * If the peer rejected, the status is set to proposalRejected.
 * If the peer accepts, the client only gets to know this once the channel
 * has been established, the status is set to channelCreated.
 * After the channel has been established, the swap update is proposed to
 * the peer. If the peer rejects it, the status is set to updateRejected.
 * Else, the channel gets closed and the status is set to closingChannel.
 * Finally, once the channel has been closed and the swap was successful, the
 * status is set to complete.
 * If the channel is being force closed, the status is forceClosing and if it
 * has been force closed, it is set to forceClosed. If the force closing fails,
 * it is set to failedClosing.
 */
export type SwapStatus =
  | "pendingProposal"
  | "noAdjTxSender"
  | "proposalRejected"
  | "updateRejected"
  | "channelCreated"
  | "closingChannel"
  | "complete"
  | "forceClosing"
  | "forceClosed"
  | "failedClosing"
  | "fundingFailed";

class Swap {
  public readonly ID: SwapID;
  private _status: SwapStatus;
  // If an error happens during the swap, this member is set accordingly and
  // the status can't be changed anymore.
  private _error?: Error;
  private _rejectionReason?: string;
  // The ID of the underlying channel once it has been created.
  private _channelID?: ChannelID;
  public readonly peerAddress: Address;
  // The first asset is the client's asset (fromAsset) and the second is the
  // peer's asset (toAsset).
  public readonly assets: [Asset, Asset];
  // The first balance is the client's balance of asset[0] and the second is the
  // peer's balance of asset[1].
  public readonly balances: bigint[];
  // The address to which the funds, i.e., the swapped assets, should be
  // withdrawn. Default is the client's address.
  public readonly withdrawalAddress?: Address;

  constructor(
    ID: ProposalID,
    peerAddress: Address,
    assets: [Asset, Asset],
    balances: bigint[],
    withdrawalAddress?: Address
  ) {
    this.ID = ID;
    this._status = "pendingProposal";
    this.peerAddress = peerAddress;
    if (assets.length !== 2) {
      throw new Error("invalid number of assets for swap");
    }
    this.assets = assets;

    if (balances.length !== 2) {
      throw new Error("invalid number of balances for swap");
    }
    this.balances = balances;
    this.withdrawalAddress = withdrawalAddress;
  }

  get status(): SwapStatus {
    return this._status;
  }

  set status(value: SwapStatus) {
    if (this.hasError) {
      const allowedErrorTransitions: SwapStatus[] = [
        "forceClosing",
        "forceClosed",
        "failedClosing",
        "fundingFailed",
      ];
      if (!allowedErrorTransitions.includes(value)) {
        throw new Error(
          "can't update status on errored swap except force closing"
        );
      }
    }
    let validTransition: boolean;
    switch (value) {
      case "noAdjTxSender":
        validTransition = this._status === "pendingProposal";
        break;
      case "fundingFailed":
        validTransition = this._status === "pendingProposal";
        break;
      case "proposalRejected":
        validTransition = this._status === "pendingProposal";
        break;
      case "channelCreated":
        validTransition = this._status === "pendingProposal";
        break;
      case "updateRejected":
        validTransition = this._status === "channelCreated";
        break;
      case "closingChannel":
        validTransition = this._status === "channelCreated";
        break;
      case "complete":
        validTransition = this._status === "closingChannel";
        break;
      case "forceClosing":
        validTransition =
          this._status === "updateRejected" ||
          this._status === "failedClosing" ||
          this._status === "channelCreated" ||
          this._status === "pendingProposal";
        break;
      case "forceClosed":
        validTransition = this._status === "forceClosing";
        break;
      case "failedClosing":
        validTransition =
          this._status === "forceClosing" || this._status === "closingChannel";
        break;
      default:
        validTransition = false;
    }
    if (!validTransition) {
      throw new Error(
        `invalid status transition from ${this._status} to ${value}`
      );
    }
    this._status = value;
  }

  /**
   * Returns a Boolean indicating whether the Swap has ended (successfully and
   * non-successfully).
   */
  get isEnded(): Boolean {
    const endStatus: SwapStatus[] = [
      "noAdjTxSender",
      "proposalRejected",
      "updateRejected",
      "complete",
      "forceClosed",
    ];
    return this.hasError || endStatus.includes(this._status);
  }

  get isRejected(): Boolean {
    return (
      this._status === "proposalRejected" || this._status === "updateRejected"
    );
  }

  get hasError(): Boolean {
    return this._error !== undefined;
  }

  get error(): Error | undefined {
    return this._error;
  }

  set error(value: Error | undefined) {
    this._error = value;
  }

  get rejectionReason(): string | undefined {
    if (!this.isRejected) {
      throw new Error("swap proposal isn't rejected");
    }
    return this._rejectionReason;
  }

  set rejectionReason(value: string | undefined) {
    if (!this.isRejected) {
      throw new Error("cannot set rejection reason if proposal not rejected");
    }
    this._rejectionReason = value;
  }

  get channelID(): ChannelID | undefined {
    return this._channelID;
  }

  set channelID(value: ChannelID | undefined) {
    if (this._channelID) {
      throw new Error("channel ID is not undefined");
    }
    this._channelID = value;
  }

  get fromAsset(): Asset {
    return this.assets[0];
  }

  get toAsset(): Asset {
    return this.assets[1];
  }

  get channelAssets(): ChannelAsset[] {
    const channelAssets = new Array<ChannelAsset>(this.assets.length);
    this.assets.forEach(
      (asst, i) =>
        (channelAssets[i] = new ChannelAsset(asst.assetHolder, asst.chainID))
    );
    return channelAssets;
  }

  get myBalances(): bigint[] {
    return [this.balances[0], 0n];
  }

  get peerBalances(): bigint[] {
    return [0n, this.balances[1]];
  }
}

@jsonObject
export class SwapJSON {
  @jsonMember(String) swapID: string;
  @jsonArrayMember(Asset) assets: [Asset, Asset];
  @jsonBigIntArrayMember() balances: bigint[];
  @jsonMember(Address) peerAddress: Address;
  @jsonMember(Address) receiverAddress?: Address;
  @jsonMember(Number) date: number;

  constructor(
    swapID: string,
    assets: [Asset, Asset],
    balances: bigint[],
    peerAddress: Address,
    date: number,
    receiverAddress?: Address
  ) {
    this.swapID = swapID;
    this.assets = assets;
    this.balances = balances;
    this.peerAddress = peerAddress;
    this.receiverAddress = receiverAddress;
    this.date = date;
  }
}

export type SwapInfo = {
  fromAsset: Asset;
  toAsset: Asset;
  fromAmount: string;
  toAmount: string;
};

export default Swap;
