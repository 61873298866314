// SPDX-License-Identifier: Apache-2.0

import React from "react";
import { useTranslation } from "react-i18next";
import Modal from "react-bootstrap/Modal";
import i18n from "i18next";

/**
 * Displays a button on the right corner of the page that opens the privacy
 * policy and an iframe to opt-out tracking.
 */
function PrivacyPolicy() {
  const { t } = useTranslation("privacyPolicy");
  const [show, setShow] = React.useState(false);

  return (
    <>
      <button className={"PrivacyLink"} onClick={() => setShow(true)}>
        {t("title")}
      </button>
      <Modal
        id={"privacy-modal"}
        className="modal-dialog-centered"
        aria-labelledby="contained-modal-title-vcenter"
        show={show}
        onHide={() => setShow(false)}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {t("title")}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>{t("policy")}</Modal.Body>
        <Modal.Footer>
          <iframe
            style={{ border: "0", height: "150px", width: "600px" }}
            src={`https://app.perun.network/matomo/index.php?module=CoreAdminHome&action=optOut&language=${i18n.language}&backgroundColor=&fontColor=&fontSize=&fontFamily=Inter`}
          />
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default PrivacyPolicy;
