// SPDX-License-Identifier: Apache-2.0

import { jsonMember, jsonObject } from "typedjson";
import PerunMessage from "./PerunMessage";
import { MessageType, MessageTypeName } from "./messageTypes";

@jsonObject
export default class ProposalResponse extends PerunMessage {
  @jsonMember(Boolean) accepted: boolean;
  @jsonMember(String) rejectReason: string;

  constructor(accepted: boolean, reason = "") {
    super();
    this.accepted = accepted;
    this.rejectReason = reason;
  }

  messageType(): MessageType {
    return ProposalResponse;
  }

  messageTypeName(): MessageTypeName {
    return "ProposalResponse";
  }
}
