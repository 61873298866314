// SPDX-License-Identifier: Apache-2.0

import { jsonMember, jsonObject, TypedJSON } from "typedjson";
import PerunMessage from "./PerunMessage";
import PerunObject from "./PerunObject";
import CustomJSON from "./customjson";
import { MessageType, MessageTypeName } from "./messageTypes";

@jsonObject
export default class Response extends PerunMessage {
  @jsonMember(Number) id: number;
  @jsonMember(PerunObject) message: PerunObject;

  constructor(id: number, message: PerunObject) {
    super();
    this.id = id;
    this.message = message;
  }

  messageType(): MessageType {
    return Response;
  }

  messageTypeName(): MessageTypeName {
    return "Response";
  }

  static toJSON(me: Response) {
    return {
      type: me.messageTypeName(),
      id: me.id,
      message: JSON.parse(TypedJSON.stringify(me.message, PerunObject)),
    };
  }

  static fromJSON(js: any): Response {
    const respMsg = TypedJSON.parse(js.message, PerunObject);

    if (respMsg && js.id !== undefined) {
      return new Response(js.id, respMsg);
    } else {
      throw new Error("could not parse request");
    }
  }
}

CustomJSON(Response);
