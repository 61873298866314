// SPDX-License-Identifier: Apache-2.0

import { jsonMember, jsonObject } from "typedjson";
import Address from "./types/address";
import PerunMessage from "./PerunMessage";
import ChannelState from "./types/channelState";
import { MessageType, MessageTypeName } from "./messageTypes";

@jsonObject
export default class ChannelInfo extends PerunMessage {
  @jsonMember(Address) peerAddress: Address;
  @jsonMember(ChannelState) state: ChannelState;

  constructor(peerAddress: Address, state: ChannelState) {
    super();
    this.peerAddress = peerAddress;
    this.state = state;
  }

  messageType(): MessageType {
    return ChannelInfo;
  }

  messageTypeName(): MessageTypeName {
    return "ChannelInfo";
  }
}
