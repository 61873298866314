// SPDX-License-Identifier: Apache-2.0

import { jsonMember, jsonObject } from "typedjson";
import Address from "./types/address";
import PerunMessage from "./PerunMessage";
import ChannelState from "./types/channelState";
import { MessageType, MessageTypeName } from "./messageTypes";
import ProposalID from "./types/poposalID";

@jsonObject
export default class ChannelProposal extends PerunMessage {
  @jsonMember(ProposalID) ID: ProposalID;
  @jsonMember(Address) peerAddress: Address;
  @jsonMember(ChannelState) state: ChannelState;

  constructor(ID: ProposalID, peerAddress: Address, state: ChannelState) {
    super();
    this.ID = ID;
    this.peerAddress = peerAddress;
    this.state = state;
  }

  messageType(): MessageType {
    return ChannelProposal;
  }

  messageTypeName(): MessageTypeName {
    return "ChannelProposal";
  }
}
