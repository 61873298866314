// SPDX-License-Identifier: Apache-2.0

import PerunMessage from "./PerunMessage";
import { jsonMember, jsonObject } from "typedjson";
import ChannelID from "./types/channelID";
import { MessageType, MessageTypeName } from "./messageTypes";
import Address from "./types/address";

@jsonObject
export default class CloseChannel extends PerunMessage {
  @jsonMember(ChannelID) id: ChannelID;
  @jsonMember(Boolean) forceClose: boolean;
  @jsonMember(Address) withdrawalAddress?: Address;

  constructor(
    channelId: ChannelID,
    forceClose = false,
    withdrawalAddress?: Address
  ) {
    super();
    this.id = channelId;
    this.forceClose = forceClose;
    this.withdrawalAddress = withdrawalAddress;
  }

  messageType(): MessageType {
    return CloseChannel;
  }

  messageTypeName(): MessageTypeName {
    return "CloseChannel";
  }
}
