// SPDX-License-Identifier: Apache-2.0

import PerunMessage from "./PerunMessage";
import { MessageType, MessageTypeName } from "./messageTypes";
import { jsonMember, jsonObject } from "typedjson";

@jsonObject()
export default class GetQuoteResponse extends PerunMessage {
  @jsonMember(Number) crossQuote: number;
  @jsonMember(Number) fromQuote: number;
  @jsonMember(Number) toQuote: number;
  @jsonMember(String) fromGas: String;
  @jsonMember(String) toGas: String;

  constructor(
    crossQuote: number,
    fromQuote: number,
    toQuote: number,
    fromGas: String,
    toGas: String
  ) {
    super();
    this.crossQuote = crossQuote;
    this.fromQuote = fromQuote;
    this.toQuote = toQuote;
    this.fromGas = fromGas;
    this.toGas = toGas;
  }

  messageType(): MessageType {
    return GetQuoteResponse;
  }

  messageTypeName(): MessageTypeName {
    return "GetQuoteResponse";
  }
}
