// SPDX-License-Identifier: Apache-2.0

import React, { useContext, useState } from "react";
import { Button, Row } from "react-bootstrap";
import Swap, { SwapID, SwapInfo, SwapStatus } from "../../channel/Swap";
import logo from "../../images/perun_logo.png";
import { AppContext } from "../../AppContext";
import { BsCheck, BsX } from "react-icons/bs";
import { BiX } from "react-icons/bi";
import { useTranslation } from "react-i18next";
import Asset from "../../api/types/asset";
import { getExplorerURL } from "../../constants/Constants";
import { TimeoutType } from "../../api/GetTimeout";
import { getTokenCode } from "./AmountAndAssetForm";

interface Props {
  swapID: SwapID;
  swapInfo: SwapInfo;
  onClose: () => void;
  onSwapCompleted: (swap: Swap) => void;
}

function SwapStatusScreen(props: Props) {
  const { t } = useTranslation();
  const ctx = useContext(AppContext);
  const [swapStatus, setSwapStatus] = useState<SwapStatus>("pendingProposal");
  const [showClose, setShowClose] = useState<Boolean>(false);
  const [swapError, setSwapError] = useState<Boolean>(false);
  const [swapRejReason, setSwapRejReason] = useState<String>();
  const [timeoutDate, setTimeoutDate] = useState<Date>();

  React.useEffect(() => {
    ctx.client.state.onSwapUpdate(props.swapID, onSwapUpdate);
  });

  const rollBackSwapButton = (
    <Button
      variant={"danger"}
      onClick={() => {
        ctx.client.forceCloseSwap(props.swapID);
      }}
    >
      {t("rollbackSwap")}
    </Button>
  );

  const failedSymbol = BsX({ size: 100, color: "red" });
  const successSymbol = BsCheck({ size: 100, color: "green" });

  const loadingContainer = (
    message: string,
    withTimeout = false
  ): JSX.Element => {
    let timeoutElem = <></>;

    if (withTimeout && timeoutDate) {
      timeoutElem = (
        <p className={"timeout-notice"}>
          {t("phaseMaxTime", { time: timeoutDate.toLocaleTimeString() })}
        </p>
      );
    }

    return (
      <>
        <div>
          <img id="perun-logo-medium" height={"100"} src={logo} alt={""} />
        </div>
        <div>
          <p className={"loading"}>
            {message}
            <span>.</span>
            <span>.</span>
            <span>.</span>
          </p>
        </div>
        {timeoutElem}
      </>
    );
  };

  const onSwapUpdate = (swap: Swap) => {
    console.log(`New swap status: ${swap.status}`);
    setSwapStatus(swap.status);
    setShowClose(swap.isEnded);
    setTimeoutDate(undefined);
    if (swap.hasError) {
      console.log(`swap errored: ${swap.error}`);
      setSwapError(true);
    }
    if (swap.isRejected) {
      setSwapRejReason(swap.rejectionReason);
    }
    if (swap.status === "complete") {
      props.onSwapCompleted(swap);
    }
  };

  const updateTimeout = (timeoutType: TimeoutType) => {
    if (timeoutDate) {
      return;
    }
    ctx.client.getTimeout(timeoutType).then((t) => {
      if (t) {
        setTimeoutDate(new Date(Date.now() + t * 1000));
      }
    });
  };

  /**
   * Returns either a block explorer link to the asset if it is a token or only
   * the asset's code.
   * @param asset
   * @param withHolder - If true and the asset is a token, the block explorer
   *  URL to the token is filtered by the client's address.
   */
  const getAssetElement = (asset: Asset, withHolder: boolean) => {
    if (asset.isERC20Token) {
      const holderAddr = withHolder
        ? ctx.client.provider.getAddress()
        : undefined;
      return (
        <a
          target="_blank"
          href={getExplorerURL(
            asset.chainID,
            asset.address,
            "token",
            holderAddr
          )}
          style={{ color: "#00C9B8" }}
        >
          {getTokenCode(asset)}
        </a>
      );
    } else {
      return getTokenCode(asset);
    }
  };

  const getSwapStatusInfo = (): JSX.Element => {
    if (swapError) {
      const swap = ctx.client.state.getSwap(props.swapID)!;
      switch (swap.status) {
        case "forceClosing":
          updateTimeout("Settle");
          return loadingContainer(t("rollingBackSwap"), true);
        case "failedClosing":
          return (
            <>
              <div>{failedSymbol}</div>
              <div>
                <p>
                  {/*We differentiate between failing closing an errored swap and a normal swap ("rolling-back" vs. "completing").*/}
                  {swapError
                    ? t("rollingBackSwapFailed")
                    : t("completingSwapFailed")}
                </p>
                <p style={{ fontSize: "smaller" }}>
                  {t("errorMessage")}:{" "}
                  <span style={{ fontStyle: "italic" }}>
                    {swap.error?.message}
                  </span>
                </p>

                <div className={"asset-form-submit"}>{rollBackSwapButton}</div>
              </div>
            </>
          );
        case "forceClosed":
          return (
            <>
              <div>{successSymbol}</div>
              <div>
                <p>{t("rolledBackSwap")}</p>
              </div>
            </>
          );
        case "fundingFailed":
          return (
            <>
              <div>{failedSymbol}</div>
              <div>
                <p>{t("fundingFailed")}</p>
                <p style={{ fontSize: "smaller" }}>
                  {t("errorMessage")}:{" "}
                  <span style={{ fontStyle: "italic" }}>
                    {swap.error?.message}
                  </span>
                </p>
              </div>
            </>
          );
        default:
          return (
            <>
              <div>{failedSymbol}</div>
              <div>
                <p>{t("errorDuringSwap")}</p>
                <p style={{ fontSize: "smaller" }}>
                  {t("errorMessage")}:{" "}
                  <span style={{ fontStyle: "italic" }}>
                    {swap.error?.message}
                  </span>
                </p>

                {swap.channelID && (
                  <div className={"asset-form-submit"}>
                    {rollBackSwapButton}
                  </div>
                )}
              </div>
            </>
          );
      }
    }
    switch (swapStatus) {
      case "pendingProposal":
        updateTimeout("Funding");
        return loadingContainer(t("sendingSwapReq"), true);
      case "noAdjTxSender":
        return (
          <>
            <div>{failedSymbol}</div>
            <div>
              <p>{t("noAdjTxSender")}</p>
              <p>{t("tryAgainLater")}</p>
            </div>
          </>
        );
      case "proposalRejected":
        return (
          <>
            <div>{failedSymbol}</div>
            <div>
              <p>{t("swapReqRejected")}</p>
              {swapRejReason && (
                <p style={{ fontSize: "smaller" }}>
                  {t("reasonGiven", { reason: swapRejReason })}
                </p>
              )}
              <p>{t("tryAgainLater")}</p>
            </div>
          </>
        );
      case "updateRejected":
        return (
          <>
            <div>{failedSymbol}</div>
            <div>
              <p>{t("swapUpdateRejected")}</p>
              {swapRejReason && (
                <p style={{ fontSize: "smaller" }}>
                  {t("reasonGiven", { reason: swapRejReason })}
                </p>
              )}
              <div className={"asset-form-submit"}>{rollBackSwapButton}</div>
            </div>
          </>
        );
      case "channelCreated":
        updateTimeout("Handle");
        return loadingContainer(t("performingSwapUpdate"), true);
      case "closingChannel":
        updateTimeout("Settle");
        return loadingContainer(t("completingSwap"), true);
      case "complete":
        return (
          <>
            <div>{successSymbol}</div>
            <div>
              <p>{t("swapSuccessful")}</p>
              <p style={{ fontSize: "smaller", marginTop: "50px" }}>
                {t("youSwapped")} {props.swapInfo.fromAmount}{" "}
                {getAssetElement(props.swapInfo.fromAsset, false)}{" "}
                {t("for").toLowerCase()} {props.swapInfo.toAmount}{" "}
                {getAssetElement(props.swapInfo.toAsset, true)}
              </p>
            </div>
          </>
        );
      case "forceClosing":
        return loadingContainer(t("rollingBackSwap"));
      case "forceClosed":
        return (
          <>
            <div>{successSymbol}</div>
            <div>
              <p>{t("rolledBackSwap")}</p>
            </div>
          </>
        );
      case "fundingFailed":
        return (
          <>
            <div>{failedSymbol}</div>
            <div>
              <p>{t("fundingFailed")}</p>
              {swapRejReason && (
                <p style={{ fontSize: "smaller" }}>
                  {t("reasonGiven", { reason: swapRejReason })}
                </p>
              )}
            </div>
          </>
        );
      // We cannot reach this case since a swap with status failedClosing always has an error.
      case "failedClosing":
        throw Error("swap cannot have status failedClosing but no error set");
    }
  };

  return (
    <>
      {showClose && (
        <div className={"close-button"} onClick={props.onClose}>
          <BiX size={20} />
        </div>
      )}
      <Row className={"justify-content-center"}>
        <div className={"swap-status"}>{getSwapStatusInfo()}</div>
      </Row>
    </>
  );
}

export default SwapStatusScreen;
