// SPDX-License-Identifier: Apache-2.0

/**
 * Displays the deployed version using the environment variables.
 * @constructor
 */
function Contact() {
  return (
    <div className="Contact">
      <a href="https://polycry.pt/">
        <img src="polycrypt-logo.ico" alt="Icon" width="80" height="80"></img>
      </a>
    </div>
  );
}

export default Contact;
