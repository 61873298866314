// SPDX-License-Identifier: Apache-2.0

import { jsonMember, jsonObject } from "typedjson";
import Address from "./types/address";
import PerunMessage from "./PerunMessage";
import { MessageType, MessageTypeName } from "./messageTypes";
import { jsonBigIntMember } from "./types/bigint";

@jsonObject
export default class SetAdjTxSender extends PerunMessage {
  @jsonBigIntMember() chainID: bigint;
  @jsonMember(Address) sender: Address;

  constructor(chainID: bigint, adjTxSender: Address) {
    super();
    this.chainID = chainID;
    this.sender = adjTxSender;
  }

  messageType(): MessageType {
    return SetAdjTxSender;
  }

  messageTypeName(): MessageTypeName {
    return "SetAdjTxSender";
  }
}
