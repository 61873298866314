// SPDX-License-Identifier: Apache-2.0

import { jsonObject } from "typedjson";
import PerunMessage from "./PerunMessage";
import { MessageType, MessageTypeName } from "./messageTypes";

@jsonObject
export default class Success extends PerunMessage {
  constructor() {
    super();
  }

  messageType(): MessageType {
    return Success;
  }

  messageTypeName(): MessageTypeName {
    return "Success";
  }
}
