// SPDX-License-Identifier: Apache-2.0

import Request from "./Request";
import Response from "./Response";
import Initialized from "./Initialized";
import ChannelProposal from "./ChannelProposal";
import ChannelCreated from "./ChannelCreated";
import UpdateChannel from "./UpdateChannel";
import ChannelClosed from "./ChannelClosed";
import ChannelInfo from "./ChannelInfo";
import SignData from "./SignData";
import SendTx from "./SendTx";
import Success from "./Success";
import Error from "./Error";
import PerunMessage from "./PerunMessage";
import { TypedJSON } from "typedjson";
import PerunObject from "./PerunObject";
import ProposalResponse from "./ProposalResponse";
import OpenChannel from "./OpenChannel";
import SignResponse from "./SignResponse";
import Initialize from "./Initialize";
import CloseChannel from "./CloseChannel";
import GetChannelInfo from "./GetChannelInfo";
import GetAssets from "./GetAssets";
import GetAssetsResponse from "./GetAssetsResponse";
import SendTxResponse from "./SendTxResponse";
import GetChains from "./GetChains";
import GetChainsResponse from "./GetChainsResponse";
import SetAdjTxSender from "./SetAdjTxSender";
import GetDecimals from "./GetDecimals";
import GetDecimalsResponse from "./GetDecimalsResponse";
import FundingError from "./FundingError";
import GetTimeout from "./GetTimeout";
import GetTimeoutResponse from "./GetTimeoutResponse";
import GetQuoteResponse from "./GetQuoteResponse";
import GetQuote from "./GetQuote";
import GetSignedState from "./GetSignedState";
import SignedState from "./SignedState";
import ShutdownClient from "./ShutdownClient";

// Runtime constant which is necessary because we want to compare with the
// `typeof Request` pendants and alike.
const _messageType = {
  Request: Request,
  Response: Response,
  Initialize: Initialize,
  Initialized: Initialized,
  ShutdownClient: ShutdownClient,
  GetAssets: GetAssets,
  GetAssetsResponse: GetAssetsResponse,
  GetChains: GetChains,
  GetChainsResponse: GetChainsResponse,
  GetDecimals: GetDecimals,
  GetDecimalsResponse: GetDecimalsResponse,
  GetTimeout: GetTimeout,
  GetTimeoutResponse: GetTimeoutResponse,
  GetQuote: GetQuote,
  GetQuoteResponse: GetQuoteResponse,
  ChannelProposal: ChannelProposal,
  ChannelCreated: ChannelCreated,
  ChannelClosed: ChannelClosed,
  ChannelInfo: ChannelInfo,
  UpdateChannel: UpdateChannel,
  OpenChannel: OpenChannel,
  CloseChannel: CloseChannel,
  GetChannelInfo: GetChannelInfo,
  SetAdjTxSender: SetAdjTxSender,
  SendTx: SendTx,
  SendTxResponse: SendTxResponse,
  SignData: SignData,
  SignResponse: SignResponse,
  ProposalResponse: ProposalResponse,
  Success: Success,
  FundingError: FundingError,
  Error: Error,
  GetSignedState: GetSignedState,
  SignedState: SignedState,
  SendSignedState: SignedState,
};

// Lift the type of _messageType into the Typesystem.
// Now the `keys` and corresponding types are accessible at compile time.
type messageType = typeof _messageType;

// MessageTypeName is a sum type consisting of all keys
// ("Request" | "OpenChannel" | "CloseChannel" | ... ) of `messageType`.
export type MessageTypeName = keyof messageType;

// MessageType is a sum type consisting of all the types for each key
// (typeof Request | typeof OpenChannel | typeof CloseChannel | ... ) of `messageType`.
export type MessageType = messageType[MessageTypeName];

// isMessageTypeName is a type predicate function telling the compiler whether or not
// the input `s` is of the concrete type `MessageTypeName`.
function isMessageTypeName(s: string): s is MessageTypeName {
  return Object.keys(_messageType).includes(s);
}

PerunObject.fromJSON = (js: any): PerunObject => {
  const raw = JSON.stringify(js.message);
  let message: PerunMessage;

  // Allows compiler to narrow the type of this variable subsequently.
  const type = js.type;
  if (isMessageTypeName(type)) {
    message = TypedJSON.parse(raw, _messageType[type])!;
  } else {
    throw new Error(`unknown type "${js.type}"`);
  }

  return new PerunObject(message);
};
