// SPDX-License-Identifier: Apache-2.0

import { jsonObject } from "typedjson";
import { MessageType, MessageTypeName } from "./messageTypes";

@jsonObject
export default abstract class PerunMessage {
  abstract messageType(): MessageType;
  abstract messageTypeName(): MessageTypeName;
}
