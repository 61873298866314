// SPDX-License-Identifier: Apache-2.0

import PerunMessage from "./PerunMessage";
import { jsonMember, jsonObject } from "typedjson";
import { MessageType, MessageTypeName } from "./messageTypes";

@jsonObject()
export default class GetDecimalsResponse extends PerunMessage {
  @jsonMember(Number) decimals: number;

  constructor(decimals: number) {
    super();
    this.decimals = decimals;
  }

  messageType(): MessageType {
    return GetDecimalsResponse;
  }

  messageTypeName(): MessageTypeName {
    return "GetDecimalsResponse";
  }
}
