// SPDX-License-Identifier: Apache-2.0

import PerunMessage from "./PerunMessage";
import { MessageType, MessageTypeName } from "./messageTypes";
import { jsonArrayMember, jsonObject } from "typedjson";
import Asset from "./types/asset";

@jsonObject
export default class GetAssetsResponse extends PerunMessage {
  @jsonArrayMember(Asset) assets: Asset[];

  constructor(assets: Asset[]) {
    super();
    this.assets = assets;
  }

  messageType(): MessageType {
    return GetAssetsResponse;
  }

  messageTypeName(): MessageTypeName {
    return "GetAssetsResponse";
  }
}
