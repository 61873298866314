// SPDX-License-Identifier: Apache-2.0

import { jsonMember, jsonObject, TypedJSON } from "typedjson";
import CustomJSON from "./customjson";
import PerunMessage from "./PerunMessage";

@jsonObject
class PerunObject {
  public static fromJSON: (js: any) => PerunObject;
  @jsonMember(PerunMessage) message: PerunMessage;

  constructor(message: PerunMessage) {
    this.message = message;
  }

  static toJSON(me: PerunObject) {
    return {
      type: me.message.messageTypeName(),
      message: JSON.parse(
        TypedJSON.stringify(me.message, me.message.messageType())
      ),
    };
  }
}

CustomJSON(PerunObject);

export default PerunObject;
