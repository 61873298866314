// SPDX-License-Identifier: Apache-2.0

import { jsonObject } from "typedjson";
import { jsonBigIntArrayMember } from "./types/bigint";
import { MessageType, MessageTypeName } from "./messageTypes";
import PerunMessage from "./PerunMessage";

@jsonObject()
export default class GetAssets extends PerunMessage {
  @jsonBigIntArrayMember() chainIDs: bigint[];

  constructor(chainIDs: bigint[]) {
    super();
    this.chainIDs = chainIDs;
  }

  messageType(): MessageType {
    return GetAssets;
  }

  messageTypeName(): MessageTypeName {
    return "GetAssets";
  }
}
