// SPDX-License-Identifier: Apache-2.0

import PerunMessage from "./PerunMessage";
import { MessageType, MessageTypeName } from "./messageTypes";
import { jsonObject } from "typedjson";

@jsonObject()
export default class GetChains extends PerunMessage {
  constructor() {
    super();
  }

  messageType(): MessageType {
    return GetChains;
  }

  messageTypeName(): MessageTypeName {
    return "GetChains";
  }
}
