// SPDX-License-Identifier: Apache-2.0

import { jsonArrayMember, jsonMember, jsonObject } from "typedjson";
import { jsonBigIntArrayMember, jsonBigIntMember } from "./bigint";
import Address from "./address";

@jsonObject()
export class ChannelAsset {
  @jsonMember(Address) assetHolder: Address;
  @jsonBigIntMember() chainID: bigint;

  constructor(assetHolder: Address, chainID: bigint) {
    this.assetHolder = assetHolder;
    this.chainID = chainID;
  }
}

@jsonObject
export default class ChannelState {
  @jsonArrayMember(ChannelAsset) assets: ChannelAsset[];
  @jsonBigIntArrayMember() balance: bigint[];
  @jsonBigIntArrayMember() peerBalance: bigint[];
  @jsonMember(Boolean) isFinal: boolean;

  constructor(
    assets: ChannelAsset[],
    balance: bigint[],
    peerBalance: bigint[],
    isFinal = false
  ) {
    this.assets = assets;
    this.balance = balance;
    this.peerBalance = peerBalance;
    this.isFinal = isFinal;
  }
}
