import { AddressLike } from "../client/Provider";

export interface BlockExplorer {
  getExplorerURL(
    address: AddressLike,
    display: "address" | "token",
    filterAddress?: AddressLike
  ): string;
}

export class Etherscan implements BlockExplorer {
  baseURL: string;

  constructor(networkName?: string) {
    this.baseURL = `https://${
      networkName ? networkName.toLowerCase() + "." : ""
    }etherscan.io`;
  }

  getExplorerURL(
    address: AddressLike,
    display: "address" | "token",
    filterAddress?: AddressLike
  ): string {
    let url = `${this.baseURL}/${display}/${address.toString()}`;
    if (display === "token" && filterAddress) {
      url += `?a=${filterAddress};`;
    }
    return url;
  }
}

export class BlockScout implements BlockExplorer {
  baseURL: string;

  constructor(baseURL: string) {
    this.baseURL = baseURL;
  }

  getExplorerURL(
    address: AddressLike,
    display: "address" | "token",
    filterAddress?: AddressLike
  ): string {
    switch (display) {
      case "address":
        return `${this.baseURL}/address/${address.toString()}`;
      case "token":
        return `${this.baseURL}/tokens/${address.toString()}`;
    }
  }
}

export class Localhost implements BlockExplorer {
  getExplorerURL(
    address: AddressLike,
    display: "address" | "token",
    filterAddress?: AddressLike
  ): string {
    return `http://blockexplorer.localhost/${display}/${address}`;
  }
}
