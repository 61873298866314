import { jsonMember, jsonObject } from "typedjson";
import PerunMessage from "./PerunMessage";
import { MessageType, MessageTypeName } from "./messageTypes";

@jsonObject()
export default class GetTimeoutResponse implements PerunMessage {
  @jsonMember(Number) timeout: number;

  constructor(timeout: number) {
    this.timeout = timeout;
  }

  messageType(): MessageType {
    return GetTimeoutResponse;
  }

  messageTypeName(): MessageTypeName {
    return "GetTimeoutResponse";
  }
}
