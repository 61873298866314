// SPDX-License-Identifier: Apache-2.0

import PerunMessage from "./PerunMessage";
import { jsonMember, jsonObject } from "typedjson";
import ChannelID from "./types/channelID";
import ChannelState from "./types/channelState";
import { MessageType, MessageTypeName } from "./messageTypes";

@jsonObject
export default class UpdateChannel extends PerunMessage {
  @jsonMember(ChannelID) id: ChannelID;
  @jsonMember(ChannelState) state: ChannelState;

  constructor(channelId: ChannelID, state: ChannelState) {
    super();
    this.id = channelId;
    this.state = state;
  }

  messageType(): MessageType {
    return UpdateChannel;
  }

  messageTypeName(): MessageTypeName {
    return "UpdateChannel";
  }
}
