// SPDX-License-Identifier: Apache-2.0

import React, { useState } from "react";
import { Button, ButtonGroup } from "react-bootstrap";
import * as config from "../config.json";
import { ChainNames } from "../constants/Constants";
import { initWeb3, switchNetwork } from "../web3/web3";
import { ethers } from "ethers";
import {
  dispatchMetamaskError,
  dispatchPerunError,
  revertMetamaskError,
} from "../events/Error";
import { useTranslation } from "react-i18next";
import { Network } from "@ethersproject/networks/src.ts/types";
import connected from "../images/symbols/connected.png";
import notConnected from "../images/symbols/not_connected.png";
import { startNetworkChangeDetection } from "../networkDetection";

interface ChainsListProps {
  onProcessChange: (isProcessing: boolean) => void;
  onConnected: (isConnected: boolean) => void;
}

function ChainsList({ onProcessChange, onConnected }: ChainsListProps) {
  const { t } = useTranslation();
  const [isClickable, setIsClickable] = useState<boolean>(true);
  const [provider, setProvider] = useState<ethers.providers.Web3Provider>();
  const [connChainID, setConnChainID] = useState<number>();

  const onNetworkChange = (newNetwork: Network) => {
    if (window.localStorage.getItem("site") === "ONBOARDING") {
      console.log(`network changed to ${newNetwork.chainId}`);
      setConnChainID(newNetwork.chainId);
      onConnected(true);
      window.localStorage.setItem("chainID", newNetwork.chainId.toString());
    } else if (window.localStorage.getItem("site") === "SWAP") {
      console.log(`network changed to ${newNetwork.chainId}`);
      setConnChainID(newNetwork.chainId);
      onConnected(true);
      if (
        window.localStorage.getItem("chainID") !== newNetwork.chainId.toString()
      ) {
        dispatchMetamaskError(
          <p>
            {t("errorUnexpectedNetwork", {
              currID: newNetwork.chainId.toString(),
              chainID: window.localStorage.getItem("chainID"),
            })}
          </p>
        );
      } else {
        revertMetamaskError();
      }
    } else if (window.localStorage.getItem("site") === "HISTORY") {
      console.log(`network changed to ${newNetwork.chainId}`);
      setConnChainID(newNetwork.chainId);
      onConnected(true);
    }
  };

  React.useEffect(() => {
    initWeb3().then((provider) => {
      setProvider(provider);
      startNetworkChangeDetection(provider, onNetworkChange);
    });
    return () => {
      setProvider(undefined);
    };
  }, []);

  const connectToChain = async (netID: number) => {
    setIsClickable(false);
    onConnected(false);
    if (!provider) {
      setIsClickable(true);
      onProcessChange(false);
      return;
    }
    onProcessChange(true);
    try {
      onProcessChange(!(await switchNetwork(provider, BigInt(netID))));
    } catch (error) {
      if (error instanceof Error) {
        dispatchPerunError(<p>{error.message}</p>);
      } else {
        dispatchPerunError(<p>{t("errorSwitchingNetwork")}</p>);
      }
    }
    setIsClickable(true);
  };

  return (
    <div className={"chains-list"}>
      <ButtonGroup vertical>
        {config.networkIDs.map((netID) => {
          return (
            <Button
              className={"chain-button"}
              key={netID}
              variant={"secondary"}
              disabled={!isClickable || netID === connChainID}
              onClick={() => connectToChain(netID)}
            >
              {ChainNames.get(BigInt(netID))}{" "}
              {netID === connChainID ? (
                <img
                  className={"conn-symbol"}
                  src={connected}
                  width={"20"}
                  alt={"connected"}
                />
              ) : (
                <img
                  className={"conn-symbol"}
                  src={notConnected}
                  width={"20"}
                  alt={"not connected"}
                />
              )}
            </Button>
          );
        })}
      </ButtonGroup>
    </div>
  );
}

export default ChainsList;
