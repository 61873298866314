// SPDX-License-Identifier: Apache-2.0

import { jsonMember, jsonObject } from "typedjson";
import Address from "./types/address";
import PerunMessage from "./PerunMessage";
import ChannelState from "./types/channelState";
import { jsonBigIntMember } from "./types/bigint";
import { MessageType, MessageTypeName } from "./messageTypes";
import ProposalID from "./types/poposalID";

@jsonObject
export default class OpenChannel extends PerunMessage {
  @jsonMember(ProposalID) proposalID: ProposalID;
  @jsonMember(Address) peerAddress: Address;
  @jsonBigIntMember() challengeDuration: bigint;
  @jsonMember(ChannelState) state: ChannelState;

  constructor(
    proposalID: ProposalID,
    peerAddress: Address,
    challengeDuration: bigint,
    state: ChannelState
  ) {
    super();
    this.proposalID = proposalID;
    this.peerAddress = peerAddress;
    this.challengeDuration = challengeDuration;
    this.state = state;
  }

  messageType(): MessageType {
    return OpenChannel;
  }

  messageTypeName(): MessageTypeName {
    return "OpenChannel";
  }
}
