import { jsonMember, jsonObject } from "typedjson";
import PerunMessage from "./PerunMessage";
import { MessageType, MessageTypeName } from "./messageTypes";

export type TimeoutType = "Default" | "Handle" | "Funding" | "Settle";

@jsonObject()
export default class GetTimeout implements PerunMessage {
  @jsonMember(String) timeoutType: TimeoutType;

  constructor(timeoutType: TimeoutType) {
    this.timeoutType = timeoutType;
  }

  messageType(): MessageType {
    return GetTimeout;
  }

  messageTypeName(): MessageTypeName {
    return "GetTimeout";
  }
}
