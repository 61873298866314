// SPDX-License-Identifier: Apache-2.0

import PerunMessage from "./PerunMessage";
import { jsonMember, jsonObject } from "typedjson";
import { MessageType, MessageTypeName } from "./messageTypes";

@jsonObject
export default class SignResponse extends PerunMessage {
  @jsonMember(Uint8Array) signature: Uint8Array;

  constructor(signature: Uint8Array) {
    super();
    this.signature = signature;
  }

  messageType(): MessageType {
    return SignResponse;
  }

  messageTypeName(): MessageTypeName {
    return "SignResponse";
  }
}
