// SPDX-License-Identifier: Apache-2.0

import { jsonMember, jsonObject } from "typedjson";
import Address from "./address";
import { jsonBigIntMember } from "./bigint";
import { ChannelAsset } from "./channelState";

export type AssetType = "ETH" | "ERC20";

@jsonObject()
class Asset {
  @jsonMember(String) public code: string;
  @jsonMember(String) public name: string;
  @jsonMember(String) public type: AssetType;
  @jsonBigIntMember() public chainID: bigint;
  @jsonMember(Address) public address: Address;
  @jsonMember(Address) public assetHolder: Address;

  constructor(
    code: string,
    name: string,
    type: AssetType,
    chainID: bigint,
    address: Address,
    assetHolder: Address
  ) {
    this.code = code;
    this.name = name;
    this.type = type;
    this.chainID = chainID;
    this.address = address;
    this.assetHolder = assetHolder;
  }

  assetID = () => makeAssetID(this.address, this.chainID);

  get isERC20Token() {
    return this.type === "ERC20";
  }

  toChannelAsset(): ChannelAsset {
    return new ChannelAsset(this.assetHolder, this.chainID);
  }
}

// The AssetID is the unique identifier for assets and is generated by the
// asset's address and chain ID of the chain it lives on.
export type AssetID = string;

export function makeAssetID(address: Address, chainID: bigint): AssetID {
  return address + chainID.toString();
}

export default Asset;
