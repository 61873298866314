// SPDX-License-Identifier: Apache-2.0

import React from "react";
import { Client } from "./client/Client";

// TODO add denomination (WEI->ETH).
export interface PerunAppState {
  client: Client;
  setClient: (client: Client) => void;
}

export const AppContext = React.createContext({} as PerunAppState);
