// SPDX-License-Identifier: Apache-2.0

import React, { FormEvent, useContext, useEffect, useState } from "react";
import Asset, { AssetID } from "../../api/types/asset";
import { AppContext } from "../../AppContext";
import { Button, Form, Row } from "react-bootstrap";
import { BsArrowDown, BsArrowRight } from "react-icons/bs";
import { useTranslation } from "react-i18next";
import { Hubs, assetLogos, validSwapPairs } from "../../constants/Constants";
import { BiX } from "react-icons/bi";
import Address from "../../api/types/address";
import { assetPrecisions } from "../../constants/Constants";
import { parseUnits } from "ethers/lib/utils";
import { AddressLike } from "../../client/Provider";
import { BigNumber } from "@ethersproject/bignumber";
import { dispatchPerunError } from "../../events/Error";
import { utils } from "ethers";
import ErrorBoundary from "../ErrorBoundary";
import ReceiverSelection from "./ReceiverSelection";
import AssetDropdown from "./AssetDropDown";
import {
  calculateMinAmount,
  calculateTransactionFee,
  updateFromAmount,
  updateToAmount,
} from "../../client/GasCalculations";
import { truncateDecimal } from "../../web3/balanceUtils";
import { dispatchPerunNotice, swapRateInfoNotice } from "../../events/Notice";

interface Props {
  fromAsset?: AssetID;
  toAsset?: AssetID;
  ownAddress: Address;
  submit: (
    fromAsset: Asset,
    toAsset: Asset,
    fromAmount: string,
    toAmount: string,
    receiverAddress: Address,
    rate: number
  ) => void;
}

function AssetAmountForm(props: Props) {
  const { t } = useTranslation();
  const ctx = useContext(AppContext);

  const [validated, setValidated] = useState(false);
  const [chainID, setChainID] = useState<bigint>();
  const [assets, setAssets] = useState(new Map<AssetID, Asset>());
  const [fromAsset, setFromAsset] = useState<AssetID>();
  const [toAsset, setToAsset] = useState<AssetID>();
  const [fromAssetError, setFromAssetError] = useState<string>("");
  const [toAssetError, setToAssetError] = useState<string>("");
  const [balString, setBalString] = useState<string>("");

  const [swapDecimals, setSwapDecimals] = useState<[number, number]>([0, 0]);
  const [exchangeRate, setExchangeRate] = useState(0);
  const [fromAssetPrecision, setFromAssetPrecision] = useState(0);
  const [toAssetPrecision, setToAssetPrecision] = useState(0);
  const [fromAmount, setFromAmount] = useState<string>();
  const [toAmount, setToAmount] = useState<string>();
  const [fromInput, setFromInput] = useState<string>();
  const [toInput, setToInput] = useState<string>();
  const [ownBalSuff, setOwnBalSuff] = useState<boolean>(true);

  const [ownChecked, setOwnChecked] = useState(true);
  const [receiverAddress, setReceiverAddress] = useState<Address>();
  const [otherReceiverAddress, setOtherReceiverAddress] = useState<string>();
  const [fromTxFee, setFromTxFee] = useState<String>("0.0000");
  const [toTxFee, setToTxFee] = useState<String>("0.0000");
  const [minSwapAmount, setMinSwapAmount] = useState<String>();
  const [validSwapAmount, setValidSwapAmount] = useState<boolean>(false);
  /**
   * setDecimals sets the swapDecimals for the assets of the swap-pair.
   */
  const setDecimals = (swapPair: Asset[]) => {
    if (!swapPair[0] || !swapPair[1]) {
      return;
    }
    Promise.all([
      ctx.client.getDecimals(swapPair[0].toChannelAsset()),
      ctx.client.getDecimals(swapPair[1].toChannelAsset()),
    ]).then((decAssets) => {
      if (decAssets[0] && decAssets[1]) {
        setSwapDecimals([decAssets[0], decAssets[1]]);
      }
    });
  };

  useEffect(() => {
    console.log("receiverAddress:", receiverAddress?.toString());
  }, [receiverAddress]);

  useEffect(() => {
    if (!assets.size) {
      const assetMap = new Map<string, Asset>();
      ctx.client
        .getAssets({ type: "all" })
        .then((aRsp) => {
          aRsp.assets.map((a) => assetMap.set(a.assetID(), a));
          setAssets(assetMap);
        })
        .catch((error) => {
          console.error("Error fetching assets:", error);
        });
      ctx.client
        .getChainID()
        .then((chainID) => setChainID(chainID))
        .catch((error) => {
          console.error("Error fetching chain id:", error);
        });
    }
  }, [assets]);

  useEffect(() => {
    setExchangeRate(0);
    if (fromAsset) {
      getBal(ctx.client.provider.getAddress(), assets.get(fromAsset!)!)
        .then(async (bal) => {
          const dec = await ctx.client.getDecimals(
            assets.get(fromAsset!)!.toChannelAsset()
          );
          const etherBalance = utils.formatUnits(bal, dec!);
          setBalString(parseFloat(etherBalance).toFixed(4).toString());
        })
        .catch((error) => {
          console.error("Error fetching balance:", error);
        });
    }
    setDecimals([assets.get(fromAsset!)!, assets.get(toAsset!)!]);
  }, [fromAsset, toAsset]);

  useEffect(() => {
    if (fromAsset && toAsset) {
      setFromAmount(undefined);
      setToAmount(undefined);
      setFromInput(undefined);
      setToInput(undefined);
      ctx.client
        .getQuote(
          assets.get(fromAsset!)!.toChannelAsset(),
          assets.get(toAsset!)!.toChannelAsset(),
          Address.fromJSON(Hubs.get(assets.get(toAsset!)!.chainID)!.address!)
        )
        .then((response) => {
          if (response === undefined || response.crossQuote === 0) {
            dispatchPerunError(<p>{t("errorGettingExchangeRate")}</p>);
            return;
          }
          const txVars = calculateTransactionFee(
            assets,
            fromAsset,
            toAsset,
            response.crossQuote,
            response.fromQuote,
            response.toQuote,
            response.fromGas,
            response.toGas
          );
          setFromTxFee(txVars.fromTxFee);
          setToTxFee(txVars.toTxFee);
          setExchangeRate(txVars.exchangeRate);
        });
      setFromAssetPrecision(
        assetPrecisions.get(assets.get(fromAsset!)!.code) || 0
      );
      setToAssetPrecision(assetPrecisions.get(assets.get(toAsset!)!.code) || 0);
    }
  }, [swapDecimals]);

  useEffect(() => {
    if (exchangeRate !== 0) {
      setMinSwapAmount(
        calculateMinAmount(exchangeRate, toTxFee, fromAssetPrecision)
      );
    }
  }, [exchangeRate, toTxFee, fromAssetPrecision]);

  useEffect(() => {
    if (fromAmount) {
      if (
        parseFloat(fromAmount!) >= parseFloat(minSwapAmount! as string) &&
        parseFloat(fromAmount!) > 0
      ) {
        setValidSwapAmount(true);
      }
    }
    if (toAmount) {
      if (parseFloat(toAmount!) > 0) {
        setValidSwapAmount(true);
      }
    }
  }, [fromAmount, toAmount]);

  const onSubmitHandler = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() && validate()) {
      let fromAmountNum, toAmountNum;
      try {
        fromAmountNum = parseUnits(fromAmount!, swapDecimals[0]);
        toAmountNum = parseUnits(toAmount!, swapDecimals[1]);
      } catch (e) {
        console.log(e);
        return;
      }

      // Safety check that the amounts are greater than 0.
      if (fromAmountNum.lte(0) || toAmountNum.lte(0)) {
        console.log("Amounts not greater than 0");
        setFromAssetError(t("amountLowerThanMin"));
        return;
      }

      if (!(await checkOwnBal(fromAmountNum))) {
        return;
      }
      setValidated(true);
      if (validate()) {
        if (!ownChecked) {
          let address: Address;
          try {
            let otherAddress = otherReceiverAddress!;
            otherAddress = otherAddress.startsWith("0x")
              ? otherAddress
              : "0x" + otherAddress;
            address = Address.fromJSON(otherAddress);
            setReceiverAddress(address);
          } catch (e) {
            console.error("invalid address");
            return;
          }
        } else {
          setReceiverAddress(props.ownAddress);
        }
        if (receiverAddress) {
          props.submit(
            assets.get(fromAsset!)!,
            assets.get(toAsset!)!,
            fromAmountNum.toString(),
            toAmountNum.toString(),
            receiverAddress!,
            exchangeRate
          );
          setValidated(false);
        }
      }
    } else {
      console.log(form.reportValidity());
    }
  };

  const checkOwnBal = async (amount: BigNumber): Promise<boolean> => {
    const ownBal = await getBal(
      ctx.client.provider.getAddress(),
      assets.get(fromAsset!)!
    );
    const ownBalSuff = amount.lt(ownBal);
    setOwnBalSuff(ownBalSuff);
    return ownBalSuff;
  };

  const getBal = (address: AddressLike, asset: Asset): Promise<bigint> => {
    switch (asset.type) {
      case "ETH":
        return ctx.client.provider.getETHBalance(undefined, address);
      case "ERC20":
        return ctx.client.provider.getERC20Balance(asset.address, address);
    }
  };

  const validate = (): boolean => {
    let valid = true;
    setFromAssetError("");
    setToAssetError("");

    if (!fromAsset) {
      setFromAssetError(t("fromTokenNotSet"));
      valid = false;
    }
    if (!toAsset) {
      setToAssetError(t("toTokenNotSet"));
      valid = false;
    }
    if (fromAsset === toAsset) {
      setToAssetError(t("invalidSwapPair"));
      valid = false;
    }
    return valid;
  };

  const handleUpdateFromAmount = (amount: string) => {
    setValidSwapAmount(false);
    setFromInput(amount);
    const amounts = updateFromAmount(
      amount,
      exchangeRate,
      fromTxFee!,
      toTxFee!,
      fromAssetPrecision,
      toAssetPrecision
    );
    setToAmount(amounts.toAmount);
    setToInput(amounts.toInput);
    setFromAmount(amounts.fromAmount);
  };

  const handleUpdateToAmount = (amount: string) => {
    setValidSwapAmount(false);
    setToInput(amount);
    const amounts = updateToAmount(
      amount,
      exchangeRate,
      fromTxFee!,
      toTxFee!,
      fromAssetPrecision,
      toAssetPrecision
    );
    setFromAmount(amounts.fromAmount);
    setToAmount(amounts.toAmount);
    setFromInput(amounts.fromInput);
  };

  const formatNumber = (num: number) => {
    const threshold = 1e-3;

    if (Math.abs(num) < threshold) {
      return num.toExponential(5);
    } else {
      return num.toFixed(5);
    }
  };

  return (
    <>
      <ErrorBoundary>
        <Row className={"flex-fill d-flex justify-content-start"}>
          <Form
            className={"swap-form"}
            noValidate
            validated={validated}
            onSubmit={onSubmitHandler}
          >
            <Form.Group className="mb-3 w-100" controlId={"fromAssetForm"}>
              <Form.Label style={{ fontSize: "110%" }}>
                {t("selectPairandAmount")}
              </Form.Label>
              <Row className={"inner-row"}>
                <div className="d-flex">
                  <AssetDropdown
                    assets={assets}
                    selectedAsset={fromAsset}
                    otherAsset={toAsset}
                    chainID={chainID}
                    onSelectAsset={(assetID) => {
                      if (assetID && toAsset !== assetID) {
                        setFromAsset(assetID);
                        if (toAsset) {
                          setDecimals([
                            assets.get(fromAsset!)!,
                            assets.get(toAsset!)!,
                          ]);
                        }
                      }
                    }}
                  />
                  <div className="d-flex flex-column">
                    <Form.Control
                      disabled={!fromAsset || !toAsset || exchangeRate === 0}
                      type="text"
                      value={fromInput || ""}
                      placeholder={"0.0"}
                      lang="en"
                      min={parseFloat(minSwapAmount! as string)}
                      step={1 / 10 ** fromAssetPrecision}
                      required
                      isInvalid={!ownBalSuff}
                      onChange={(event) =>
                        handleUpdateFromAmount(event.currentTarget.value)
                      }
                    />
                  </div>
                  <Form.Control.Feedback type="invalid">
                    {ownBalSuff
                      ? t("invalidSwapAmount")
                      : t("insufficientBalance")}
                  </Form.Control.Feedback>
                </div>
                <div className={"tx-fee"}>
                  <p>+ {truncateDecimal(fromTxFee! as string, 4, "UP")}</p>
                </div>
              </Row>
              {balString !== "" && (
                <p>{t("balanceIs", { balance: balString })}</p>
              )}
              {fromAssetError.length > 0 && (
                <span className="invalid-feedback" style={{ display: "block" }}>
                  {fromAssetError}
                </span>
              )}
            </Form.Group>
            <BsArrowDown id={"swap-arrow-down"} />
            <Row className={"rate"}>
              {toAsset && fromAsset ? (
                <p>
                  {t("rate")}: 1 {getTokenCode(assets.get(fromAsset!)!)} ≈{" "}
                  {formatNumber(exchangeRate)}{" "}
                  {getTokenCode(assets.get(toAsset!)!)}
                </p>
              ) : null}
            </Row>

            <Form.Group className="mb-3 w-100" controlId={"toAssetForm"}>
              <Row className={"inner-row"}>
                <div className="d-flex">
                  <AssetDropdown
                    assets={assets}
                    selectedAsset={toAsset}
                    otherAsset={fromAsset}
                    onSelectAsset={(assetID) => {
                      if (assetID && fromAsset !== assetID) {
                        setToAsset(assetID);
                        if (toAsset) {
                          setDecimals([
                            assets.get(fromAsset!)!,
                            assets.get(toAsset!)!,
                          ]);
                        }
                      }
                    }}
                  />
                  <Form.Control
                    disabled={!fromAsset || !toAsset || exchangeRate === 0}
                    type="text"
                    value={toInput || ""}
                    placeholder={"0.0"}
                    lang="en"
                    min={1 / 10 ** toAssetPrecision}
                    step={1 / 10 ** toAssetPrecision}
                    required
                    onChange={(event) =>
                      handleUpdateToAmount(event.currentTarget.value)
                    }
                  />
                  <Form.Control.Feedback type="invalid">
                    {t("invalidSwapAmount")}
                  </Form.Control.Feedback>
                </div>
                <div className={"tx-fee"}>
                  <p>- {truncateDecimal(toTxFee! as string, 4, "UP")}</p>
                </div>
              </Row>
              {toAssetError.length > 0 && (
                <span className="invalid-feedback" style={{ display: "block" }}>
                  {toAssetError}
                </span>
              )}
            </Form.Group>
            {fromAmount && toAmount && validSwapAmount && (
              <Row className={"inner-row"}>
                <div className={"amount-selection"}>
                  <p>
                    {truncateDecimal(fromAmount, 4, "UP")}{" "}
                    {getTokenCode(assets.get(fromAsset!)!)}
                  </p>
                  <BsArrowRight className="arrow-icon" />
                  <p>
                    {truncateDecimal(toAmount, 4, "DOWN")}{" "}
                    {getTokenCode(assets.get(toAsset!)!)}
                  </p>
                </div>
                <div
                  className="help-button"
                  onClick={() =>
                    dispatchPerunNotice(
                      swapRateInfoNotice(
                        fromTxFee,
                        toTxFee,
                        exchangeRate,
                        assets.get(fromAsset!)!.chainID,
                        assets.get(toAsset!)!.chainID,
                        getTokenCode(assets.get(fromAsset!)!),
                        getTokenCode(assets.get(toAsset!)!)
                      )
                    )
                  }
                >
                  <span>?</span>
                </div>
              </Row>
            )}
            {!validSwapAmount && (
              <Row className={"inner-row"}>
                <p>
                  {t("minAmount")}
                  {minSwapAmount}
                </p>
              </Row>
            )}
            <Button
              className={"clear-button"}
              variant={"light"}
              onClick={() => {
                setFromAsset(undefined);
                setToAsset(undefined);
                setFromAmount(undefined);
                setToAmount(undefined);
                setFromInput(undefined);
                setToInput(undefined);
                setFromAssetError("");
                setToAssetError("");
                setBalString("");
                setValidated(false);
                setFromTxFee("0.0000");
                setToTxFee("0.0000");
              }}
            >
              <BiX size={20} />
              {t("clear")}
            </Button>
            <br />
            <ReceiverSelection
              ownAddress={props.ownAddress}
              submit={(address: String, oC: Boolean) => {
                if (address !== props.ownAddress.toString() && !oC) {
                  setOtherReceiverAddress(address as string);
                  setOwnChecked(false);
                } else {
                  setOwnChecked(true);
                }
              }}
            ></ReceiverSelection>
            <Button
              className={"asset-form-submit custom-button padding"}
              variant={"primary"}
              type={"submit"}
            >
              {t("next")}
            </Button>
          </Form>
        </Row>
      </ErrorBoundary>
    </>
  );
}

/**
 * Returns a component displaying the asset with its logo.
 *
 * @param asset
 * @param withName - Whether the asset should be displayed with its full name or
 * only by its code, e.g., "Ethereum (ETH)" or "ETH".
 * @param withBorder - Whether the component should be inside a container with
 * a border.
 */
export const getTokenTitle = (
  asset: Asset,
  withName: boolean,
  withBorder: boolean
): JSX.Element => {
  const logo = assetLogos.get(asset.code);
  const img = logo ? (
    <img className={"asset-logo"} src={logo} alt={`${asset.code}-logo`} />
  ) : (
    <></>
  );
  const name = withName
    ? `${asset.name} (${getTokenCode(asset)})`
    : getTokenCode(asset);

  if (withBorder) {
    return (
      <div className={"asset-container"}>
        {img} {name}
      </div>
    );
  } else {
    return (
      <>
        {img} {name}
      </>
    );
  }
};

export const getTokenCode = (asset: Asset): string => {
  if (asset.code === "GOEETH" && asset.chainID === 5n) {
    return "GOE-ETH";
  } else if (asset.code === "GOE") {
    return "GOE-ERC20";
  } else if (asset.code === "BLC") {
    return "BLC-ERC20";
  } else if (asset.code === "SEPETH") {
    return "SEP-ETH";
  } else if (asset.code === "RLC") {
    return "ETH-RLC";
  } else {
    return asset.code;
  }
};

export const validPair = (fromAsset: Asset, toAsset: Asset): boolean => {
  return validSwapPairs.some(
    (pair) => pair.includes(fromAsset.code) && pair.includes(toAsset.code)
  );
};

export default AssetAmountForm;
