// SPDX-License-Identifier: Apache-2.0

import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import translationEN from "./en/translation.json";
import privacyPolicyEN from "./en/privacyPolicy.json";

export const resources = {
  en: {
    translation: translationEN,
    privacyPolicy: privacyPolicyEN,
  },
} as const;

i18n.use(initReactI18next).init({
  lng: "en",
  fallbackLng: "en",
  resources,
});
