// SPDX-License-Identifier: Apache-2.0

import { Bytes, ethers, utils, Wallet } from "ethers";
import {
  BlockTag,
  TransactionRequest,
  TransactionResponse,
} from "@ethersproject/abstract-provider";
import { JsonRpcProvider } from "@ethersproject/providers/src.ts/json-rpc-provider";
import { Listener } from "@ethersproject/abstract-provider/src.ts";
import Address from "../api/types/address";

export type AddressLike = Address | String;

const ERC20Abi = ["function balanceOf(address owner) view returns (uint256)"];

// The Provider provides an abstraction for the functionality of an Ethereum
// node. Is used for the client instead of requiring a concrete Provider
// implementation.
export default interface Provider {
  getChainID: () => Promise<bigint>;
  signMessage: (msg: Bytes | string) => Promise<string>;
  sendTransaction: (tx: TransactionRequest) => Promise<TransactionResponse>;
  getAddress: () => Address;
  // If address is undefined, it uses the own address for the balance queries.
  getETHBalance: (
    blockTag?: BlockTag,
    address?: AddressLike
  ) => Promise<bigint>;
  getERC20Balance: (
    tokenAddress: AddressLike,
    address?: AddressLike
  ) => Promise<bigint>;
  onBlock: (listener: Listener) => void;
}

export class Web3Provider implements Provider {
  private provider: ethers.providers.Web3Provider;
  private readonly address: string;

  constructor(provider: ethers.providers.Web3Provider, address: string) {
    this.provider = provider;
    this.address = address;
  }

  public getChainID(): Promise<bigint> {
    return this.provider.getNetwork().then((net) => BigInt(net.chainId));
  }

  public signMessage(msg: Bytes | string): Promise<string> {
    const signer = this.provider!.getSigner();
    return signer.signMessage(utils.arrayify(msg));
  }

  public sendTransaction(tx: TransactionRequest): Promise<TransactionResponse> {
    const signer = this.provider!.getSigner();
    return signer.sendTransaction(tx);
  }

  public onBlock(listener: Listener) {
    this.provider.on("block", listener);
  }

  public offBlock(listener: Listener) {
    this.provider.off("block", listener);
  }

  getETHBalance(blockTag?: BlockTag, address?: AddressLike): Promise<bigint> {
    return this.provider
      .getBalance(address?.toString() || this.address, blockTag)
      .then((bal) => bal.toBigInt());
  }

  getAddress(): Address {
    return Address.fromJSON(this.address);
  }

  getERC20Balance(
    tokenAddress: AddressLike,
    address?: AddressLike
  ): Promise<bigint> {
    const erc20 = new ethers.Contract(
      tokenAddress.toString(),
      ERC20Abi,
      this.provider
    );
    return erc20.balanceOf(address?.toString() || this.address);
  }
}

export class JSONRPCProvider implements Provider {
  private readonly provider: JsonRpcProvider;
  private wallet: Wallet;

  constructor(
    secretKey: string,
    ethURL: string,
    networkName: string,
    chainID: number
  ) {
    this.provider = new ethers.providers.JsonRpcProvider(ethURL, {
      name: networkName,
      chainId: chainID,
    });

    this.wallet = new ethers.Wallet(secretKey, this.provider);
  }

  getChainID(): Promise<bigint> {
    return this.provider.getNetwork().then((net) => {
      return BigInt(net.chainId);
    });
  }

  getProvider(): JsonRpcProvider {
    return this.provider;
  }

  sendTransaction(tx: TransactionRequest): Promise<TransactionResponse> {
    return this.wallet.sendTransaction(tx);
  }

  signMessage(msg: Bytes | string): Promise<string> {
    return this.wallet.signMessage(utils.arrayify(msg));
  }

  onBlock(listener: Listener): void {
    this.provider.on("block", listener);
  }

  getAddress(): Address {
    return Address.fromJSON(this.wallet.address);
  }

  getETHBalance(blockTag?: BlockTag, address?: AddressLike): Promise<bigint> {
    return this.provider
      .getBalance(address?.toString() || this.wallet.address, blockTag)
      .then((bal) => bal.toBigInt());
  }

  getERC20Balance(
    tokenAddress: AddressLike,
    address?: AddressLike
  ): Promise<bigint> {
    const erc20 = new ethers.Contract(
      tokenAddress.toString(),
      ERC20Abi,
      this.provider
    );
    return erc20.balanceOf(address?.toString() || this.wallet.address);
  }
}
