// SPDX-License-Identifier: Apache-2.0

import React, { useContext, useEffect, useState } from "react";
import * as fa from "react-icons/fa";
import Modal from "react-bootstrap/Modal";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import { Trans, useTranslation } from "react-i18next";
import {
  ChainNames,
  discordLink,
  feedbackEmail,
  getExplorerURL,
} from "../constants/Constants";
import { AppContext } from "../AppContext";
import Asset, { AssetID } from "../api/types/asset";

/**
 * Displays a help circle button that when clicked opens a modal with a FAQ and
 * contact information.
 */
export function HelpMenu() {
  const { t } = useTranslation();
  const [showHelp, setshowHelp] = React.useState(false);
  const [assets, setAssets] = useState(new Map<AssetID, Asset>());
  const ctx = useContext(AppContext);

  useEffect(() => {
    if (!assets.size && ctx.client?.initialized) {
      const assetMap = new Map<string, Asset>();
      ctx.client.getAssets({ type: "all" }).then((aRsp) => {
        aRsp.assets.map((a) => assetMap.set(a.assetID(), a));
        setAssets(assetMap);
      });
    }
  }, [assets, ctx.client?.initialized]);

  const getAssetList = (): JSX.Element => {
    if (!ctx.client?.initialized) {
      return <p>{t("A_tokenInfoNotConnected")}</p>;
    } else {
      return (
        <>
          <ul>
            {Array.from(assets.values())
              .filter((a) => a.isERC20Token)
              .map((token) => (
                <li key={token.assetID()}>
                  <Trans
                    i18nKey="A_tokenInfo"
                    values={{
                      tokenName: token.name,
                      chainName: ChainNames.get(token.chainID),
                      address: token.address.toString().substr(0, 8),
                    }}
                  >
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href={getExplorerURL(
                        token.chainID,
                        token.address,
                        "token"
                      )}
                      style={{ color: "#00C9B8" }}
                    ></a>
                  </Trans>
                </li>
              ))}
          </ul>
        </>
      );
    }
  };

  return (
    <>
      <button className="HelpCircle" onClick={() => setshowHelp(true)}>
        <fa.FaQuestion />
      </button>
      <Modal
        className="modal-dialog-centered"
        id={"help-modal"}
        aria-labelledby="contained-modal-title-vcenter"
        show={showHelp}
        onHide={setshowHelp}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {t("help")}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h5>{t("FAQ")}</h5>
          <Accordion allowZeroExpanded={true}>
            <AccordionItem>
              <AccordionItemHeading>
                <AccordionItemButton>
                  {t("Q_proposalStuck")}
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <p>{t("A_proposalStuck")}</p>
              </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
              <AccordionItemHeading>
                <AccordionItemButton>
                  {t("Q_fundingFailed")}
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <p>{t("A_fundingFailed")}</p>
              </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
              <AccordionItemHeading>
                <AccordionItemButton>{t("Q_settleFailed")}</AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <p>{t("A_settleFailed")}</p>
              </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
              <AccordionItemHeading>
                <AccordionItemButton>{t("Q_tokensHidden")}</AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <p>{t("A_tokensHidden")}</p>
              </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
              <AccordionItemHeading>
                <AccordionItemButton>{t("Q_tokenInfo")}</AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>{getAssetList()}</AccordionItemPanel>
            </AccordionItem>
          </Accordion>
          <hr />
          {/* We have to use the Trans component here, in order to
           interpolate links in our text.*/}
          <Trans i18nKey="contactInfo">
            {t("contactInfo")}
            <a href={`mailto:${feedbackEmail}`} style={{ color: "#00C9B8" }}>
              {{ feedbackEmail }}
            </a>
            .
          </Trans>
          <br />
          <Trans i18nKey={"discordInfo"}>
            {t("discordInfo")}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={discordLink}
              style={{ color: "#00C9B8" }}
            >
              Discord
            </a>
          </Trans>
        </Modal.Body>
      </Modal>
    </>
  );
}
