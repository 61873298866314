// SPDX-License-Identifier: Apache-2.0

import { jsonObject } from "typedjson";
import PerunMessage from "./PerunMessage";
import { MessageType, MessageTypeName } from "./messageTypes";

@jsonObject
export default class Initialized extends PerunMessage {
  constructor() {
    super();
  }

  messageType(): MessageType {
    return Initialized;
  }

  messageTypeName(): MessageTypeName {
    return "Initialized";
  }
}
