// SPDX-License-Identifier: Apache-2.0

import PerunMessage from "./PerunMessage";
import { MessageType, MessageTypeName } from "./messageTypes";
import { jsonMember, jsonObject } from "typedjson";
import { ChannelAsset } from "./types/channelState";

@jsonObject()
export default class GetDecimals extends PerunMessage {
  @jsonMember(ChannelAsset) asset: ChannelAsset;

  constructor(asset: ChannelAsset) {
    super();
    this.asset = asset;
  }

  messageType(): MessageType {
    return GetDecimals;
  }

  messageTypeName(): MessageTypeName {
    return "GetDecimals";
  }
}
