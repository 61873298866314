// SPDX-License-Identifier: Apache-2.0

import PerunMessage from "./PerunMessage";
import { jsonObject } from "typedjson";
import { TransactionResponse } from "@ethersproject/abstract-provider";
import { MessageType, MessageTypeName } from "./messageTypes";
import { jsonTxMember } from "./types/transaction";

@jsonObject
export default class SendTxResponse extends PerunMessage {
  @jsonTxMember() transaction: TransactionResponse;

  constructor(transaction: TransactionResponse) {
    super();
    this.transaction = transaction;
  }

  messageType(): MessageType {
    return SendTxResponse;
  }

  messageTypeName(): MessageTypeName {
    return "SendTxResponse";
  }
}
