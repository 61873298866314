// SPDX-License-Identifier: Apache-2.0

import { useTranslation } from "react-i18next";
import { discordLink, feedbackEmail } from "../constants/Constants";
import { Dropdown, DropdownButton } from "react-bootstrap";

function ContactInfo() {
  const { t } = useTranslation();

  return (
    <DropdownButton
      id="contact-dropdown"
      title={t("contact")}
      className={"contact"}
      drop="up"
    >
      <Dropdown.Item
        onClick={() => {
          window.open(discordLink, "_blank");
        }}
      >
        <span
          style={{
            color: "#00C9B8",
            cursor: "pointer",
            textDecoration: "underline",
          }}
        >
          Discord
        </span>
      </Dropdown.Item>
      <Dropdown.Item
        onClick={() => {
          window.location.href = `mailto:${feedbackEmail}`;
        }}
      >
        <span
          style={{
            color: "#00C9B8",
            cursor: "pointer",
            textDecoration: "underline",
          }}
        >
          Feedback
        </span>
      </Dropdown.Item>
    </DropdownButton>
  );
}

export default ContactInfo;
