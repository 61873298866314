// SPDX-License-Identifier: Apache-2.0

import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { useTranslation } from "react-i18next";

export interface InfoProps {
  show: boolean;
  toggleShow: () => void;
  title?: string;
  content?: JSX.Element;
  submitText?: string;
  onSubmit?: () => void;
  cancelText?: string;
  onCancel?: () => void;
  autoClose?: Boolean;
}

/**
 * Returns a Modal with the given title and content.
 * @param props -
 * @constructor
 */
export default function InfoModal(props: InfoProps) {
  const { t } = useTranslation();

  return (
    <Modal
      className="infomodal"
      aria-labelledby="contained-modal-title-vcenter"
      show={props.show}
      onHide={props.toggleShow}
      centered
      backdrop={"static"}
      keyboard={false}
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {props.title ? props.title : t("information")}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>{props.content || <></>}</Modal.Body>
      <Modal.Footer>
        {props.cancelText && (
          <Button
            variant="secondary"
            onClick={() => {
              if (props.onCancel !== undefined) {
                props.onCancel?.();
                props.toggleShow();
              }
            }}
          >
            {props.cancelText}
          </Button>
        )}
        {props.submitText && (
          <Button
            className={"custom-button"}
            variant={"primary"}
            onClick={() => {
              props.onSubmit?.();
              props.toggleShow();
            }}
          >
            {props.submitText}
          </Button>
        )}
        {!props.submitText && !props.cancelText && props.autoClose && (
          <p>{t("willClose")}</p>
        )}
      </Modal.Footer>
    </Modal>
  );
}
