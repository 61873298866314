// SPDX-License-Identifier: Apache-2.0

import React, { useContext, useState } from "react";
import { Container } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import Asset from "../api/types/asset";
import { AppContext } from "../AppContext";
import SwapSummary from "./forms/SwapSummary";
import { parse as uuidParse, v4 as uuid } from "uuid";
import { FaChevronLeft } from "react-icons/fa";
import Address from "../api/types/address";
import ProposalID from "../api/types/poposalID";
import Swap, { SwapID, SwapInfo, SwapJSON } from "../channel/Swap";
import SwapStatusScreen from "./forms/SwapStatusScreen";
import { TypedJSON } from "typedjson";
import { getExplorerURL, Hub, Hubs } from "../constants/Constants";
import { formatUnits } from "ethers/lib/utils";
import AssetAmountForm from "./forms/AmountAndAssetForm";

const SwapStages = [
  "ASSET_AMOUNT_SELECT",
  "SWAP_SUMMARY",
  "SWAP_PERFORM",
] as const;

function SwapScreen() {
  const { t } = useTranslation();
  const [swapStage, setSwapStage] = useState<number>(0);
  const [swapPair, setSwapPair] = useState<[Asset, Asset]>();
  const [hub, setHub] = useState<Hub>();
  const [swapAmount, setSwapAmount] = useState<[string, string]>();
  const [swapDecimals, setSwapDecimals] = useState<[number, number]>([0, 0]);
  // The swapRate is the exchange rate from swapPair[0] to swapPair[1].
  const [swapRate, setSwapRate] = useState<number>();
  const [receiverAddr, setReceiverAddr] = useState<Address>();
  const [swapID, setSwapID] = useState<SwapID>();
  const ctx = useContext(AppContext);

  const submitSwap = () => {
    if (!swapPair || !swapAmount) {
      return;
    }
    const swapID = new Uint8Array(32);
    swapID.set(uuidParse(uuid()));

    const swap = new Swap(
      ProposalID.fromJSON(swapID),
      Address.fromJSON(hub!.address),
      swapPair,
      [BigInt(swapAmount[0]), BigInt(swapAmount[1])],
      receiverAddr
    );

    setSwapID(swap.ID);

    setSwapStage(2);
    ctx.client.initiateSwap(swap);
  };

  const saveSwap = (swap: Swap) => {
    console.log("saving swap to localStorage");
    const swapJSON = TypedJSON.stringify(
      new SwapJSON(
        swap.ID.toString(),
        swap.assets,
        swap.balances,
        swap.peerAddress,
        Date.now(),
        receiverAddr
      ),
      SwapJSON
    );

    let swapsArr: Array<String>;
    try {
      swapsArr = JSON.parse(localStorage.getItem("swaps") || "[]");
      // We need to stringify the already included swaps because they are
      // parsed as objects.
      swapsArr = swapsArr.map((value) => JSON.stringify(value));
      swapsArr.push(swapJSON);
    } catch (e) {
      console.error("swap history in localStorage is malformed, resetting...");
      swapsArr = [swapJSON];
    }
    localStorage.setItem("swaps", `[${swapsArr.toString()}]`);
  };

  const getForm = (): JSX.Element => {
    if (swapStage >= SwapStages.length) {
      console.error("invalid swap stage");
      return <></>;
    }

    switch (SwapStages[swapStage]) {
      case "ASSET_AMOUNT_SELECT":
        return (
          <AssetAmountForm
            fromAsset={swapPair ? swapPair[0].assetID() : undefined}
            toAsset={swapPair ? swapPair[1].assetID() : undefined}
            ownAddress={ctx.client.provider.getAddress()}
            submit={async (
              fromAsset,
              toAsset,
              fromAmount,
              toAmount,
              receiverAddress,
              rate
            ) => {
              setSwapPair([fromAsset, toAsset]);
              await setDecimals([fromAsset, toAsset]);
              const hub = Hubs.get(toAsset.chainID);
              setSwapAmount([fromAmount, toAmount]);
              setReceiverAddr(receiverAddress);
              setSwapRate(rate);
              if (hub) {
                setHub(hub);
                nextSwapPage();
              } else {
                console.error(
                  `could not find hub for chainID ${toAsset.chainID}`
                );
              }
            }}
          />
        );
      case "SWAP_SUMMARY":
        if (!swapPair || !swapAmount || !swapRate || !receiverAddr) {
          console.error(
            "swapPair, swapAmount, ReceiverAddress and swapRate must not be undefined for SwapSummary"
          );
          return <></>;
        }
        return (
          <SwapSummary
            swapInfo={getSwapInfo()!}
            exchangeRate={swapRate}
            receiverAddress={receiverAddr.toString()}
            hubName={hub!.name}
            hubURL={getExplorerURL(
              swapPair[1].chainID,
              hub!.address,
              "address"
            )}
            submit={submitSwap}
            cancel={() => {
              setSwapStage(0);
            }}
          />
        );
      case "SWAP_PERFORM":
        if (!swapID || !swapPair || !swapAmount) {
          return <></>;
        }
        return (
          <SwapStatusScreen
            swapID={swapID}
            swapInfo={getSwapInfo()!}
            onSwapCompleted={saveSwap}
            onClose={() => {
              setSwapPair(undefined);
              setSwapAmount(undefined);
              setSwapID(undefined);
              setSwapStage(0);
            }}
          />
        );
    }
  };

  /**
   * setDecimals sets the swapDecimals for the assets of the swap-pair.
   */
  const setDecimals = (swapPair: Asset[]) => {
    Promise.all([
      ctx.client.getDecimals(swapPair[0].toChannelAsset()),
      ctx.client.getDecimals(swapPair[1].toChannelAsset()),
    ]).then((decAssets) => {
      if (decAssets[0] && decAssets[1]) {
        setSwapDecimals([decAssets[0], decAssets[1]]);
      }
    });
  };

  const getSwapInfo = (): SwapInfo | undefined => {
    if (!swapPair || !swapAmount) {
      return undefined;
    }
    return {
      fromAsset: swapPair[0],
      toAsset: swapPair[1],
      fromAmount: formatUnits(swapAmount[0], swapDecimals[0] || 0),
      toAmount: formatUnits(swapAmount[1], swapDecimals[1] || 0),
    };
  };

  /**
   * Sets swapStage to the next swapStage or, if the current swapStage is the
   * last one, resets to the first one.
   */
  const nextSwapPage = () => {
    if (swapStage >= SwapStages.length - 1) {
      setSwapStage(0);
    } else {
      setSwapStage(swapStage + 1);
    }
  };

  /**
   * Sets swapStage to the previous swapStage if the current swapStage isn't the
   * first one.
   */
  const previousSwapPage = () => {
    if (swapStage > 0) {
      setSwapStage(swapStage - 1);
    } else {
      console.error("cannot go back on first swapStage");
    }
  };

  return (
    <>
      <Container className={"SwapContainer mt-2 w-75"} fluid>
        <h3>{t("tokenSwap")}</h3>
        <hr className={"title-divider"} />
        {swapStage > 0 && swapStage < 2 && (
          <div className={"back-button"} onClick={previousSwapPage}>
            <FaChevronLeft />{" "}
          </div>
        )}
        <div className={"container-fluid d-flex h-100 flex-column"}>
          {getForm()}
        </div>
      </Container>
    </>
  );
}

export default SwapScreen;
