// SPDX-License-Identifier: Apache-2.0

import PerunMessage from "./PerunMessage";
import { MessageType, MessageTypeName } from "./messageTypes";
import { jsonMember, jsonObject } from "typedjson";
import { ChannelAsset } from "./types/channelState";
import Address from "./types/address";

@jsonObject()
export default class GetQuote extends PerunMessage {
  @jsonMember(ChannelAsset) fromAsset: ChannelAsset;
  @jsonMember(ChannelAsset) toAsset: ChannelAsset;
  @jsonMember(Address) hub: Address;

  constructor(
    fromAsset: ChannelAsset,
    toAsset: ChannelAsset,
    hubAddress: Address
  ) {
    super();
    this.fromAsset = fromAsset;
    this.toAsset = toAsset;
    this.hub = hubAddress;
  }

  messageType(): MessageType {
    return GetQuote;
  }

  messageTypeName(): MessageTypeName {
    return "GetQuote";
  }
}
