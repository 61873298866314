// SPDX-License-Identifier: Apache-2.0

import ChannelID from "../api/types/channelID";
import Address from "../api/types/address";
import ChannelState from "../api/types/channelState";

/**
 * The representation of a channel for the client.
 */
export default class Channel {
  private readonly _id: ChannelID;
  private readonly _peerAddress: Address;
  private _state: ChannelState;

  constructor(id: ChannelID, peerAddress: Address, state: ChannelState) {
    this._id = id;
    this._peerAddress = peerAddress;
    this._state = state;
  }

  /**
   * @returns The address of the other peer of the channel.
   */
  get peerAddress(): Address {
    return this._peerAddress;
  }

  /**
   * @returns The channel ID.
   */
  get id(): ChannelID {
    return this._id;
  }

  /**
   * @returns The current state.
   */
  get state(): ChannelState {
    return this._state;
  }

  /**
   * Sets the current state to the given one.
   *
   * @param newState -
   */
  updateState(newState?: ChannelState): void {
    if (newState) {
      this._state = newState;
    }
  }

  /**
   * @returns The current balance of the client.
   */
  get myBalance(): bigint[] {
    return this._state.balance;
  }

  /**
   * @returns The current balance of the other peer.
   */
  get peerBalance(): bigint[] {
    return this._state.peerBalance;
  }
}
