// SPDX-License-Identifier: Apache-2.0

export const dispatchPerunError = (content: JSX.Element) => {
  document.dispatchEvent(
    new CustomEvent<JSX.Element>("PerunError", { detail: content })
  );
};
export const dispatchMetamaskError = (content: JSX.Element) => {
  document.dispatchEvent(
    new CustomEvent<JSX.Element>("OpenMetamaskError", { detail: content })
  );
};
export const revertMetamaskError = () => {
  document.dispatchEvent(new CustomEvent<JSX.Element>("CloseMetamaskError"));
};
