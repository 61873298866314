// SPDX-License-Identifier: Apache-2.0

import { jsonArrayMember, jsonMember, jsonObject } from "typedjson";
//import { ChannelAsset } from "./channelState";

@jsonObject
export class Params {
  @jsonArrayMember(Number) id: Array<number>;
  @jsonMember(Number) challengeDuration: number;
  @jsonArrayMember(String) parts: Array<String>;
  @jsonMember(String) app: String;
  @jsonMember(String) appType: String;
  @jsonMember(String) nonce: String;
  @jsonMember(Boolean) ledgerChannel: Boolean;
  @jsonMember(Boolean) virtualChannel: Boolean;

  constructor(
    pId: Array<number>,
    challengeDuration: number,
    parts: Array<String>,
    app: String,
    appType: String,
    nonce: String,
    ledgerChannel: Boolean,
    virtualChannel: Boolean
  ) {
    this.id = pId;
    this.challengeDuration = challengeDuration;
    this.parts = parts;
    this.app = app;
    this.appType = appType;
    this.nonce = nonce;
    this.ledgerChannel = ledgerChannel;
    this.virtualChannel = virtualChannel;
  }
}

@jsonObject()
export class SubAlloc {
  @jsonArrayMember(Number) asId: number[];
  @jsonArrayMember(Number) bals: number[];
  @jsonArrayMember(Number) indexMap: number[];

  constructor(asId: number[], bals: number[], indexMap: number[]) {
    this.asId = asId;
    this.bals = bals;
    this.indexMap = indexMap;
  }
}

@jsonObject
export default class Allocation {
  @jsonArrayMember(String) assets: String[];
  @jsonArrayMember(String, { dimensions: 2 }) balances: Array<Array<String>>;
  @jsonArrayMember(SubAlloc, {
    serializer: (subAlloc: SubAlloc) => {
      return {
        asId: subAlloc.asId,
        bals: subAlloc.bals,
        indexMap: subAlloc.indexMap,
      };
    },
  })
  locked: Array<SubAlloc>;

  constructor(
    assets: String[],
    balances: Array<Array<String>>,
    locked: Array<SubAlloc>
  ) {
    this.assets = assets;
    this.balances = balances;
    this.locked = locked;
  }
}

@jsonObject()
export class Data {
  @jsonMember(Function) Clone!: () => Data;

  constructor(Clone: () => Data) {
    this.Clone = Clone;
  }
}

@jsonObject
export class SState {
  @jsonArrayMember(Number) id: number[];
  @jsonMember(Number) version: number;
  @jsonMember(String) app: String;
  @jsonMember(String) appType: String;
  @jsonMember(Allocation) allocation: Allocation;
  @jsonMember(String) data: string;
  @jsonMember(Boolean) isFinal: boolean;

  constructor(
    id: number[],
    version: number,
    app: String,
    appType: String,
    allocation: Allocation,
    data: string,
    isFinal: boolean
  ) {
    this.id = id;
    this.version = version;
    this.app = app;
    this.appType = appType;
    this.allocation = allocation;
    this.data = data;
    this.isFinal = isFinal;
  }
}
