// SPDX-License-Identifier: Apache-2.0

import Channel from "../channel/Channel";
import ChannelID from "../api/types/channelID";
import SwapID from "../api/types/poposalID";
import Swap from "../channel/Swap";

export type SwapUpdateHandler = (swap: Swap) => void;

/**
 * The state of the client considering the balance, swaps and channels.
 */
class State {
  private balance?: BigInt;
  private readonly swaps: Map<string, Swap>;
  private readonly channels: Map<string, Channel>;
  private swapUpdateHandler: Map<SwapID, SwapUpdateHandler>;
  public balanceChangedHandler?: (newBal: BigInt) => void;

  constructor(channels?: Map<string, Channel>, swaps?: Map<string, Swap>) {
    this.channels = new Map<string, Channel>();
    this.swaps = new Map<string, Swap>();
    this.swapUpdateHandler = new Map();
    if (channels) {
      channels.forEach((ch, key) => this.channels.set(key, ch));
    }
    if (swaps) {
      swaps.forEach((sw, key) => this.swaps.set(key, sw));
    }
  }

  public getBalance(): BigInt | undefined {
    return this.balance;
  }

  public getChannel(ID: ChannelID): Channel | undefined {
    return this.channels.get(ID.toString());
  }

  public getSwap(ID: SwapID): Swap | undefined {
    return this.swaps.get(ID.toString());
  }

  public onSwapUpdate(swapID: SwapID, handler: SwapUpdateHandler) {
    this.swapUpdateHandler.set(swapID, handler);
  }

  public offSwapUpdate(swapID: SwapID) {
    this.swapUpdateHandler.delete(swapID);
  }

  /**
   * Updates the balance to the given balance and calls the balance changed
   * event handler.
   * @param newBal -
   */
  public updateBalance(newBal: BigInt) {
    this.balance = newBal;
    if (this.balanceChangedHandler) {
      this.balanceChangedHandler(newBal);
    }
  }

  /**
   * Adds a channel to the state.
   * @param channel -
   * @throws - Error if the state already contains a channel with the same id.
   */
  public addChannel(channel: Channel) {
    if (this.channels.has(channel.id.toString())) {
      throw Error("channel with same ID already included");
    }
    this.channels.set(channel.id.toString(), channel);
  }

  public addSwap(swap: Swap) {
    if (this.swaps.has(swap.ID.toString())) {
      throw Error("swap with same ID already included");
    }
    this.swaps.set(swap.ID.toString(), swap);
  }

  /**
   * Updates a channel in the state.
   * @param channel -
   * @throws - Error if the channel isn't contained in state.
   */
  public updateChannel(channel: Channel) {
    if (!this.channels.has(channel.id.toString())) {
      throw Error("channel not found");
    }
    this.channels.set(channel.id.toString(), channel);
  }

  /**
   * Emits a swap update event.
   * @param swapID - The ID of the swap that has been updated.
   * @throws - Error if the swap isn't include in the state.
   */
  public emitUpdateSwap(swapID: SwapID) {
    if (!this.swaps.has(swapID.toString())) {
      throw Error("swap not found");
    }
    const handler = this.swapUpdateHandler.get(swapID);
    if (handler) {
      handler(this.getSwap(swapID)!);
    }
    return;
  }

  /**
   * Removes a channel by its ID and emits a channel closed event.
   * @param id -
   * @throws - Error if the channel isn't contained in the state.
   */
  public removeChannel(id: ChannelID) {
    if (!this.channels.has(id.toString())) {
      throw Error("channel not found");
    }
    this.channels.delete(id.toString());
  }
}

export default State;
