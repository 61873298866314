// SPDX-License-Identifier: Apache-2.0

import PerunMessage from "./PerunMessage";
import { jsonMember, jsonObject } from "typedjson";
import ChannelID from "./types/channelID";
import { MessageType, MessageTypeName } from "./messageTypes";

@jsonObject
export default class GetChannelInfo extends PerunMessage {
  @jsonMember(ChannelID) id: ChannelID;

  constructor(id: ChannelID) {
    super();
    this.id = id;
  }

  messageType(): MessageType {
    return GetChannelInfo;
  }

  messageTypeName(): MessageTypeName {
    return "GetChannelInfo";
  }
}
